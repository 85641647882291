import React from 'react';
import { Collapse, Grid, List, ListItem, ListItemIcon, ListItemText, Theme  } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDetectScreenSize, useEnv } from '@ephatta/components';
import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Menu, useDrawerMenu } from '../../../hooks/menu/useDrawerMenu';

export const Header = () => {

    const classes = useStyle();
    const env = useEnv()
    const menus = useDrawerMenu()
    const navigate = useNavigate()
    const location = useLocation()
    const [menuOpened, setMenuOpened] = useState<Array<Menu>>([])

    useEffect(() => {
        
    }, [])

    const handleMenuClick = (menu: Menu) => {

        if (menu.route !== '#') {
            navigate(menu.route)
        }
        
        if (menu.menus) {
            
            setMenuOpened((v) => {
                const values = [...v]
                const index = values.findIndex((item) => item === menu)
                index === -1 ? values.push(menu) : values.splice(index, 1)
                return values
            })
        }
    }
    
    return (
        <Grid item xs={12} className={classes.drawerContainer}>
            <List>
                {
                    menus.map((menu, index) =>
                        menu &&
                            <>
                                <ListItem
                                    className={`rounded-8 ${classes.listItem}`}
                                    selected={
                                        menu.route !== '' ? 
                                        location.pathname === `/${menu.route}` : 
                                        (location.pathname === `/${menu.route}` || location.pathname === '/')
                                    }
                                    button
                                    key={index}
                                    onClick={() => handleMenuClick(menu)}
                                >
                                        {
                                            menu.icon &&
                                            <ListItemIcon>
                                                <menu.icon />
                                            </ListItemIcon>
                                        }
                                        
                                        <ListItemText primary={menu.title} />
                                        {
                                            menu.menus &&
                                            <>
                                                {
                                                    menuOpened.includes(menu) ? <ExpandLess /> : <ExpandMore />
                                                }
                                            </>
                                        }
                                </ListItem>
                                {
                                    menu.menus && 
                                    <Collapse in={menuOpened.includes(menu)} timeout="auto" unmountOnExit>
                                        <List component="div" className={classes.subMenu}>
                                            {
                                                menu.menus.map((subMenu, _index) => (
                                                    <ListItem 
                                                        button 
                                                        className={classes.nested}
                                                        onClick={() => handleMenuClick(subMenu)}>
                                                        <ListItemText primary={subMenu.title} />
                                                    </ListItem>
                                                ))
                                            }
                                        </List>
                                    </Collapse>
                                }
                            </>
                    )
                }
            </List>
        </Grid>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    drawerContainer: {
        position: 'fixed',
        top: 60,
        left: 0,
        bottom: 0,
        zIndex: 980,
        width: '18%',
        paddingTop: 40,
        background: theme.palette.primary.light,
        textAlign: 'center',
        color: theme.palette.primary.main,
        border: 'none !important',
        overflow: 'auto',
        '& .MuiList-root': {
            paddingLeft: 0,
        },
        [theme.breakpoints.down('lg')]: {
            width: '26%'
        },
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            inset: 'unset',
            width: 'unset'
        },
    },
    listItem: {
        color: theme.palette.primary.light,
        textAlign: 'left',
        "&.MuiMenuItem-root.Mui-selected": {
            backgroundColor: theme.palette.primary.main,
            "& .MuiListItemText-root .MuiTypography-displayBlock": {
                fontWeight: 700
            }
        },
        '& .MuiListItemIcon-root': {
            minWidth: 28
        },
        '& .MuiListItemIcon-root svg': {
            color: theme.palette.primary.main,
        },
        '& .MuiListItemText-primary': {
            fontWeight: "bold",
            fontSize: 14,
        },
    },
    subMenu: {
        '&.MuiList-root': {
            marginLeft: 48
        }
    },
    nested: {
        paddingLeft: theme.spacing(4),
        '& .MuiListItemText-primary': {
            fontWeight: 500,
            fontSize: 14,
        },
    },
}))

export default Header;
