
import { TSEO } from "@ephatta/services"
import { useEphattaMutation } from "hooks"
import { useSEOServices } from "./useSEOServices"

export const useSEOUpdate = (id: number) => {
    const service = useSEOServices()
    return useEphattaMutation<TSEO, TSEO, any>(
        (payload: TSEO) => service.update(id, payload),
        {
            log: (data, variables) => `Page SEO ${variables?.url} mise à jour`
        }
    )
}