import { EphattaRouteProps } from '@ephatta/components';
import React from 'react';

const Dashboard = React.lazy(() => import('./Dashboard'))

const Routes: Array<EphattaRouteProps> = [
    {
        path: '/event',
        element: <Dashboard/>
    },
]

export default Routes
