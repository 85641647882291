import { EphattaRouteProps } from '@ephatta/components';
import React from 'react'


const StaticPage = React.lazy(() => import('./StaticPage'))
const Edit = React.lazy(() => import('./Edit'))

const Routes: Array<EphattaRouteProps> = [
    {
        path: '/static-page/edit',
        element: <Edit/>
    },   
    {
        path: '/static-page/edit/:url',
        element: <Edit/>
    },
    {
        path: '/static-page',
        element: <StaticPage />
    }
]

export default Routes
