import { EditorState } from "draft-js";
import { useCallback } from "react"
// @ts-ignore
import { toggleCustomInlineStyle } from 'draftjs-utils';

export const useTextToogleInlineStyle = () => {
    return useCallback((editorState: EditorState, style: string, value: string) => {
        return toggleCustomInlineStyle(editorState, style, value) as EditorState
        
    }, [])
}
