import { useMemo } from "react"

export const useImageObjectFits = () => {

    return useMemo(() => {
        return [
            { value: 'contain', label: 'Contenu' },
            { value: 'cover', label: 'Couverture' },
            { value: 'fill', label: 'Remplir' },
            { value: 'scale-down', label: 'Taille réel plus petite' },
            { value: 'none', label: 'Aucun' },
        ]
    }, [])
}
