import { TAdvertEquipmentCreatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useAdvertEquipmentsService } from "./useAdvertEquipmentsService";

export const useAdvertEquipmentCreate = () => {
    const service = useAdvertEquipmentsService()
    return useEphattaMutation(
        (payload: Array<TAdvertEquipmentCreatePayload>) => service.create(payload),
        {
            log: (data, variables) => {
                const [item] = variables!
                return `Equipement <a href="/equipements">${item.value}(${item.culture})</a> ajouté.`
            }
        }
    )
}