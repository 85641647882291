import React from 'react'
import {EphattaRouteProps} from "@ephatta/components";

const Dashboard = React.lazy(() => import('./Dashboard'));
const Details = React.lazy(() => import('./Details'));

const Routes: EphattaRouteProps[] = [
    {
        path: '/comments',
        element: <Dashboard/>
    },
    {
        path: '/comments/:id',
        element: <Details/>
    },
]

export default Routes
