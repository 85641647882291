import { TStaticPage } from '@ephatta/services';
import { useEphattaMutation } from '../..';
import { useStaticPageServices } from './useStaticPageServices';

export const useInsertStaticPage = () => {
    const service = useStaticPageServices()
    return useEphattaMutation(
        (data: TStaticPage) => service.insert(data),
        {
            showSuccessMessage: true,
            successMessage: 'Page ajoutée avec succès',
            log: (data, variables) => {
                return `Page statique <a href="/static-page/edit/${variables?.url}">${variables?.url}</a> ajouté.`
            }
        }
    )
} 