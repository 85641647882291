import { TCustomerForcingPayload } from "@ephatta/services"
import { useEphattaMutation } from "@hooks/utils"
import { useTranslation } from "react-i18next"
import { useCustomerService } from "./useCustomerService"

export const useCustomerForceEmail = () => {
    const service = useCustomerService()
    const {t} = useTranslation()

  return useEphattaMutation( 
    (payload:TCustomerForcingPayload) => service.forceConfirmEmail(payload),
    {
      successMessage: t("Page.MyProfile.PersonalInformation.Success.Message"),
      log: (data, variables) => `Validation d'email <${variables?.email}>`
    }
  )
}
