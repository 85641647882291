import { TStaticPage } from '@ephatta/services';
import { useEphattaMutation } from '../..';
import { useStaticPageServices } from './useStaticPageServices';

export const useDeleteStaticPage = () => {
    const service = useStaticPageServices()
    return useEphattaMutation(
        (data: TStaticPage) => service.delete(data.id!),
        {
            successMessage: 'Page supprimée avec succès',
            log: (data, variables) => {
                return `Page statique <a href="/static-page/edit/${variables?.url}">${variables?.url}</a> supprimé.`
            }
        }
    )
}