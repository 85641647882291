import React from "react";
import { Redo, Undo } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"
import { useEditor } from "@craftjs/core";

export const ToolbarUndoRedo = () => {

    const { canUndo, canRedo, actions } = useEditor((state, query) => ({
        canUndo: query.history.canUndo(),
        canRedo: query.history.canRedo(),
    }));

    return (
        <Box display="flex">
            <IconButton disabled={!canUndo} onClick={() => actions.history.undo()}>
                <Undo />
            </IconButton>

            <IconButton disabled={!canRedo} onClick={() => actions.history.redo()}>
                <Redo />
            </IconButton>
        </Box>
    )
}
