import { ButtonSubmit } from "@components/button"
import { SerializedNode, useEditor } from "@craftjs/core"
import { Edit, Save, Visibility } from "@mui/icons-material"
import { Box, Button, ButtonProps } from "@mui/material"
import React, { useCallback } from "react"
import { ToolbarBreakpoint } from "./ToolbarBreakpoint"
import { ToolbarUndoRedo } from "./ToolbarUndoRedo"

export const Toolbar = (props: ToolbarProps) => {

    const { enabled, actions, query } = useEditor((state, query) => ({
        enabled: state.options.enabled,
        canUndo: query.history.canUndo(),
        canRedo: query.history.canRedo(),
    }));

    const handleSaveClick = () => {
        const value = query.serialize()
        const obj = omitUselessProps(JSON.parse(value))
        props.onSave?.(obj, query.getSerializedNodes())
    }

    const omitUselessProps = useCallback((props: any): string => {
        Object.keys(props).forEach((key) => {
            const item = props[key]
            if (item.displayName === 'Text' && item.props.editorState) {
                delete item.props.editorState
            }
        })

        return JSON.stringify(props)
    }, [])

    const handleOverviewClick = () => {
        actions.setOptions((options) => (options.enabled = !enabled))
    }

    return (
        <Box display="flex" justifyContent={enabled ? "space-between" : "flex-end"} width="100%">
            {
                enabled &&
                <>
                    <ToolbarUndoRedo />
                    <ToolbarBreakpoint />
                </>
            }
            <Box display="flex" alignItems="center">
                <Box display="flex">
                    {props.showViewBtn &&
                        <Button
                            color="secondary"
                            variant={!enabled ? 'contained' : 'text'}
                            onClick={handleOverviewClick}
                            startIcon={enabled ? (<Visibility />) : <Edit />}>
                            {enabled ? 'Aperçu' : 'Modifier'}
                        </Button>}

                    {
                        enabled && props.showSaveBtn &&
                        <ButtonSubmit
                            color="primary"
                            variant="contained"
                            {...props.ButtonSaveProps}
                            isSubmit={props.isSaving}
                            onClick={handleSaveClick}
                            startIcon={(<Save />)}>
                            Sauvegarder
                        </ButtonSubmit>
                    }
                </Box>
            </Box>
        </Box>
    )
}

export type ToolbarProps = {
    isSaving?: boolean
    showViewBtn?: boolean,
    showSaveBtn?: boolean,
    onSave?: (json: string, data?: Record<string, SerializedNode>) => void
    ButtonSaveProps?: ButtonProps
}
