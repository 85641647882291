import { useMemo } from "react"

export const useTextTransforms = () => {

    return useMemo((): Array<TitleProps> => ([
        {
            label: 'Aucun',
            value: 'none'
        },
        {
            label: 'Capitale',
            value: 'capitalize'
        },
        {
            label: 'Majuscule',
            value: 'uppercase'
        },
        {
            label: 'Minuscule',
            value: 'lowercase'
        },
    ]), [])
}

type TitleProps = {
    label: string
    value: string
}

