import { ChevronLeft, ChevronRight } from "@mui/icons-material"
import { Box, IconButton, Paper, Typography } from "@mui/material"
import React, { useState } from "react"
import { Toolbox } from "./Toolbox"

export const ToolboxWraper = () => {

    
    const [hideText, setHideText] = useState(true)

    return (
        <Box width={hideText ? '90px' : '320px'} sx={{ transition: '0.2s' }}>
            <Paper>
                <Typography textAlign="center">
                    <IconButton onClick={() => setHideText((v) => !v)}>
                        {
                            !hideText &&
                            <ChevronLeft />
                        }

                        {
                            hideText &&
                            <ChevronRight />
                        }
                    </IconButton>
                    {
                        !hideText && 'Eléménts'
                    }
                </Typography>
                <Toolbox hideText={hideText} />
            </Paper>
        </Box>
    )
}
