import { useEphattaMutation } from '../..';
import { useStaticPageServices } from './useStaticPageServices';
import { TStaticPage } from '@ephatta/services';

export const useUpdateStaticPage = () => {

    const service = useStaticPageServices()
    
    return useEphattaMutation(
        (data: TStaticPage) => service.update(data),
        {   
            showSuccessMessage: true,
            successMessage: 'Page mise à jour avec succès',
            log: (data, variables) => {
                return `Page statique <a href="/static-page/edit/${variables?.url}">${variables?.url}</a> modifié.`
            }
        }
    )
}