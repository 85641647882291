import { DraftEntityMutability, EditorState, RichUtils } from "draft-js";
import { useCallback } from "react"

export const useTextCreateEntity = () => {
    return useCallback((editorState: EditorState, entity: EntityProps) => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            entity.type,
            entity.mutable || "MUTABLE",
            entity.data
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, {
            currentContent: contentStateWithEntity,
        });
        return RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
        )
    }, [])
}

type EntityProps = {
    type: string
    mutable?: DraftEntityMutability
    data?: Record<string, string | number>
}
