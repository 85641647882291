import { TLandingPageCreatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useLandigPageType } from "./useLandigPageType";
import { useLandingPageService } from "./useLandingPageService";

export const useLandingPageUpdate = () => {
    const service = useLandingPageService()
    const getLandingPageType = useLandigPageType()
    return useEphattaMutation<boolean, any, any>(
        (payload: TLandingPageCreatePayload) => service.update(payload),
        {
            log: (data, variables) => {
                return `Landing page ${getLandingPageType(variables?.type || '')} <a href="/landing/content/edit/${variables?.id}">${variables?.title}</a> modifié.`
            }
        }

    )
}
