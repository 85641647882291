import { TAdvertBookingUpdate } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { useAdvertBookingService } from "./useAdvertBookingService";

export const useAdvertBookingUpdate = () => {
    const {t} = useTranslation()
    const service = useAdvertBookingService()
    return useEphattaMutation(
        (payload: TAdvertBookingUpdate) => service.update(payload),
        {
            successMessage: t("Booking.actions.updated.successMessage"),
            log(data, variables) {
                return `Réservation <a href="/booking/${variables?.id}">${variables?.id}</a> modifié`
            }
        }
    )
}