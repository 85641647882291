import { useTranslations } from "@ephatta/components"
import { useMemo } from "react"

export const useExportTypes = () => {

    const [
        USERS,
        COMMENTS,
        ADVERT,
        BOOKING,
        CONTACT_IMPORT,
        TRANSACTIONS,
        INBOX,
        RELATIONSHIP
    ] = useTranslations(i18n)

    return useMemo(() => ([
        {
            value: 'customers',
            label: USERS
        },
        {
            value: 'comment',
            label: COMMENTS
        },
        {
            value: 'advert',
            label: ADVERT
        },
        {
            value: 'booking',
            label: BOOKING
        },
        {
            value: 'contactImports',
            label: CONTACT_IMPORT
        },
        {
            value: 'transactions',
            label: TRANSACTIONS
        },
        {
            value: 'messages',
            label: INBOX
        },
        {
            value: 'relationship',
            label: RELATIONSHIP
        },
    ]), [])
}

const i18n = [
    "MiddleOffice.Statistics.ExportType.User.label",
    "MiddleOffice.Statistics.ExportType.Comments.label",
    "MiddleOffice.Statistics.ExportType.Advert.label",
    "MiddleOffice.Statistics.ExportType.Booking.label",
    "MiddleOffice.Statistics.ExportType.contactImports.label",
    "MiddleOffice.Statistics.ExportType.transactions.label",
    "MiddleOffice.Statistics.ExportType.inbox.label",
    "MiddleOffice.Statistics.ExportType.relationship.label",
]
