import React, { useEffect } from "react"
import { GenericDialog, GenericDialogType } from "components/dialog"
import { Box, TextField } from "@mui/material"
import { useTextFontSizeFormData } from "./hooks/useTextFontSizeFormData"
import _ from "lodash"

export const TextFontSizeDialog = ({ onSubmit, value, ...props }: TextFontSizeDialogProps) => {

    const { formData, getTextFieldProps, isValid, setFormData } = useTextFontSizeFormData(value)

    useEffect(() => {
        if (!_.isEqual(value, formData)) {
            setFormData(value)
        }
    }, [value])

    const handleSubmit = () => {
        if (isValid()) {
            onSubmit?.(formData)
        }
    }

    return (
        <GenericDialog
            {...props}
            title="Ajouter une taille de texte"
            onConfirm={() => handleSubmit()}>
                <Box display="flex" flexDirection="column" gap={3} mt={5}>
                    <TextField
                        type="number"
                        label="Taille"
                        fullWidth
                        {...getTextFieldProps('fontSize')}
                        />
                </Box>
        </GenericDialog>
    )
}

export type TextFontSizeDialogProps = Omit<GenericDialogType, 'onSubmit'> & {
    value: TextFontSizeDialogValue
    onSubmit?: (value: TextFontSizeDialogValue) => void
}

export type TextFontSizeDialogValue = {
    fontSize: string
}
