import React from 'react'
import { Box, Tab, Tabs, Theme } from "@mui/material";
import { useScreenBreakPoint } from "components/craft-editor/hooks/useScreenBreakPoint";
import { useScreenBreakpointOptions } from "../toolbox/hooks/useScreenBreakpointOptions";
import { makeStyles } from '@mui/styles';

export const ToolbarBreakpoint = () => {

    const options = useScreenBreakpointOptions()
    const [breakpoint, setBreakpoint] = useScreenBreakPoint()
    const classes = useStyle()

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setBreakpoint(newValue)
    };

    return (
        <Box overflow="hidden">
            <Tabs
                className={classes.tabs}
                value={breakpoint || 'xl'} 
                onChange={handleChange}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile>
                {
                    options.map((item, index) => (
                        <Tab key={index} {...item} iconPosition="start" {...a11yProps(index)} />
                    ))
                }
            </Tabs>
        </Box>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    tabs: {
        '& .MuiTab-root': {
            minHeight: 'unset'
        }
    }
}))

function a11yProps(index: number) {
    return {
        id: `property-breakpoint-tab-${index}`,
        'aria-controls': `property-breakpoint-tabpanel-${index}`,
    };
}
