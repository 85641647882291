import { MenuItem, Select, SelectProps, TextField, TextFieldProps } from "@mui/material"
import React, { useEffect, ChangeEvent } from "react"
import { InputLabel } from "components"
import { useDebounce, useFormData } from "hooks"
import { Grid } from "@mui/material"
import { FilterSortProps } from "./FilterOrderBy"
import _ from "lodash"

export const FilterControl = (props: FilterControlProps) => {

    const { formData, handleInputChange, setFormData } = useFormData<FilterControlValue>({
        formData: props.value
    })

    const debounce = useDebounce()
    const debounceNewProps = useDebounce()

    useEffect(() => {
        debounceNewProps(() => {
            !_.isEqual(formData, props.value) && setFormData(props.value)
        }, 750)
    }, [props.value])

    useEffect(() => {
        debounce(() => {
            props.onChange?.(formData)
        }, 250)
    }, [formData])

    return (
        <div className={`${props.className}`}>
            <Grid container alignItems="center">
                {
                    props.label &&
                    <Grid item md={2} xs={2}>
                        <InputLabel {...props.InputLabel} label={props.label} />
                    </Grid>
                }
                
                <Grid item md={5} xs={4} style={{ paddingRight: 30 }}>
                    <Select
                        {...props.SelectProps}
                        style={{ width: '100%', ...props.SelectProps?.style }}
                        value={formData.field || ''}
                        onChange={(e) => {
                            handleInputChange('field', e.target.value)
                            handleInputChange('key', '')
                        }}
                    >
                        {
                            props.fields.map((item, index) => (
                                <MenuItem key={index} value={item.value}>{ item.label }</MenuItem>
                            ))
                        }
                    </Select>
                </Grid>

                <Grid item md={5} xs={6}>
                    {
                        props.onRenderControl && props.onRenderControl?.({
                            formData, 
                            handleInputChange
                        })
                        
                    }

                    {
                        !props.onRenderControl &&
                        <TextField 
                            fullWidth
                            {...props.TextField} 
                            label=""
                            value={formData.key || ''} 
                            onChange={(e: ChangeEvent<HTMLInputElement>) => handleInputChange('key', e.target.value)} />
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export type FilterControlValue = {
    field?: string | null
    key?: string | null
}

export type RenderControlParam = {
    formData: FilterControlValue
    handleInputChange: (key: string, value: any) => void
}

export type FilterControlProps = FilterSortProps & {
    TextField?: TextFieldProps
    SelectProps?: SelectProps
    value: FilterControlValue
    onRenderControl?: (param: RenderControlParam) => React.ReactNode
}
