import React from 'react'
import {EphattaRouteProps} from "@ephatta/components";

const Dashboard = React.lazy(() => import('./Dashboard'));
const UserGlobalDetails = React.lazy(() => import('./Details'));

const Routes: EphattaRouteProps[] = [
    {
        path: '/users',
        element: <Dashboard/>
    },
    {
        path:'users/:userId',
        element: <UserGlobalDetails />,
        disableScrollTopOnChange: true
    }
]

export default Routes
