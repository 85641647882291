
export const migrateContentEditableToDraftJS = (str: string) => {
    let result: string = str
    result = changeColor(result)
    result = changeInlineStyle(result)
    return result

}

const changeColor = (str: string) => {
    const regExp = /<\s*font[^>]*color=\"([a-zA-Z0-9\#\(\)\,]*)\"\s*>(.*?)<\s*\/\s*font>/g
    const result = str.replace(regExp, (match) => {
        return match.replace(regExp, `<span style="color: $1">$2</span>`) ;
    })
    return result
}

const changeInlineStyle = (str: string) => {
    const result = str.replace(/<\s*b[^>]*>(.*?)<\s*\/\s*b>/g, `<strong>$1</strong>`)
                        .replace(/<\s*u[^>]*>(.*?)<\s*\/\s*u>/g, `<ins>$1</ins>`)
                        .replace(/<\s*i[^>]*>(.*?)<\s*\/\s*i>/g, `<em>$1</em>`)
    return result
}
