import React, { useCallback } from "react";
import { useNode } from "@craftjs/core";
import { TextField } from "@mui/material"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { ContainerProperty } from "./ContainerProperty";

export const VideoVimeoProperty = () => {

    const {
        actions: { setProp },
        videoId,
    } = useNode((node) => ({
        videoId: node.data.props.videoId,
    }));

    const getId = useCallback((url: string) => {
        // var regExp = /(http|https)?:\/\/(www\.|player\.)?vimeo\.com\/(?:channels\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/;
        // var match = url.match(regExp);
        // return (match&&match[4].length==9)? match[4] : '';
        return url
    }, [])

    return (
        <>
            
            <ContainerProperty />

            <PropertyItemTitle>Lien</PropertyItemTitle>
            <TextField 
                label="Lien vers la video Vimeo" 
                fullWidth value={videoId || ''} 
                onChange={(e) => setProp((props: any) => props.videoId = getId(e.target.value)) } 
                />
        </>
    )
}
