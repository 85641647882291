import { useEnv, useTranslations } from "@ephatta/components"
import { Menu } from "@mui/icons-material"
import { AppBar, Box, Button, IconButton, Toolbar as MUIToolbar, Typography } from "@mui/material"
import { useNavigate } from "react-router"

export const Toolbar = (props: Props) => {

    const env = useEnv()
    const navigate = useNavigate()

    const [
        DECONNECT_USER
    ] = useTranslations(i18n)

    return (
        <AppBar
            color="default"
            position="fixed">  
            <MUIToolbar>
                <Box sx={{ display: { sm: 'block', md: 'none' } }}>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={props.onDrawerToggle}>
                        <Menu />
                    </IconButton>
                </Box>
                <Typography variant="h6" noWrap m="auto">
                    <a href="/">
                        <img src="/assets/img/logo.png" style={{ height: 48, marginTop: 5 }} alt={process.env.REACT_APP_NAME} />
                    </a>
                </Typography>

                {
                    env && 
                    <Button color="secondary" variant="contained" className="rounded" onClick={() => navigate('/logout')}>
                        {DECONNECT_USER}
                    </Button>
                }
                
            </MUIToolbar>
        </AppBar>
    )
}

const i18n = [
    'MiddleOffice.Account.Deconnection'
]

type Props = {
    onDrawerToggle?: () => void
}
