import React from 'react'
import Dashboard from './Dashboard';
import {EphattaRouteProps} from "@ephatta/components";

const Routes: EphattaRouteProps[] = [
    {
        path: '/seo',
        element: <Dashboard/>
    },
]

export default Routes
