import { TSEO } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useSEOServices } from "./useSEOServices";

export const useSEOInsert = () => {
    const service = useSEOServices()
    return useEphattaMutation<TSEO, TSEO, any>(
        (payload: TSEO) => service.insert(payload),
        {
            log: (data, variables) => `Page SEO ${variables?.url} créée`
        }
    )
}