import { TAdminStatisticPayload } from '@ephatta/services'
import { useEphattaQuery } from '@hooks/utils'
import _ from 'lodash'
import { useStatisticServices } from './useStatisticServices'

export const useAdminStats = (payload: TAdminStatisticPayload, suspense?: boolean) => {
    const services = useStatisticServices()
  return useEphattaQuery(
    ["GET.Admin.Statistics", payload],
    () => services.adminStats(payload),
    {
      suspense,
      enabled: !_.isEmpty(payload.endDate) && !_.isEmpty(payload.startDate)
    }
  )
}
