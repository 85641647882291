import { TCustomerForcingPayload } from "@ephatta/services"
import { useEphattaMutation } from "@hooks/utils"
import _ from "lodash"
import { useTranslation } from "react-i18next"
import { useCustomerService } from "./useCustomerService"

export const useCustomerForcePhone = () => {
    const service = useCustomerService()
    const {t} = useTranslation()

  return useEphattaMutation( 
    (payload:TCustomerForcingPayload) => service.forceConfirmPhone(_.omit(payload, "name")),
    {
      successMessage: t("Page.MyProfile.PersonalInformation.Success.Message"),
      log: (data, variables) => `Validation du numéro de téléphone <${variables?.phoneNumber }> forcé pour ${variables?.name} qui a l'identifiant <a href="/users/${variables?.userId}">${variables?.userId}</a>`
    }
  )
}
