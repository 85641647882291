import { useFormData } from "hooks"
import { TextLinkDialogValue } from "../TextLinkDialog"

export const useTextLinkFormData = (initialValue: TextLinkDialogValue) => {

    return useFormData<TextLinkDialogValue>({
        formData: initialValue,
        required: [
            'href'
        ] ,
    })
}
