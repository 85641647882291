import { useCallback } from "react"

export const useLandigPageType = () => {

    return useCallback((type: string) => {
        const dict =  {
            'advert': "annonces",
            "content": "contenu"
        }
        return dict[type]
    }, [])
}
