
import { useFilterEffect } from "./useUrlFilterEffect"
import { useFormData } from "hooks"
import { useUrlFilterValue } from "."
import { useEffect } from "react"

/**
 * 
 * @deprecated use useUrlParamState instead
 */
export const useUrlFilter = <TData = TUrlFilter>(pathname: string, defaultValue?: TData) => {

    const _defaultValue = useUrlFilterValue<TData>()
    const { formData, handleInputChange, setFormData } = useFormData<TData>({
        formData: _defaultValue as any
    })

    useEffect(() => {
        if (defaultValue) {
            setFormData((v) => ({
                ...defaultValue,
                ...v,
            }))
        }
    }, [])

    useFilterEffect(formData, pathname)

    return {
        formData,
        handleInputChange,
        setFormData
    }
}

export type TUrlFilter = Record<string, any>
