import { ThemeOptions } from "@mui/material";
import { DefaultTheme } from "@ephatta/components";

const primary = {
    main: '#061566',
    light: '#ffffff'
}

const secondary = {
    main: '#80cab1',
    light: '#f4f4f4'
}


const text = {
    primary: '#061566',
    secondary: '#80cab1',
    disabled: 'rgba(138, 138, 138, 1)'
}

export const Theme: ThemeOptions = {
    ...DefaultTheme,
    palette: {
        primary,
        secondary,
        text,
    },
    typography: {
        fontFamily: [
            "Montserrat",
            "Circe",
            "Roboto",
            "Helvetica Neue",
            "Arial",
            "sans-serif"
          ].join(",")
    },
    components: {
        MuiPaper: {

        },
        MuiAvatar: {
            styleOverrides: {
                root: {
                    backgroundColor: primary.main
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    padding: 10
                }
            }
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '&:hover:not(.Mui-selected)': {
                        backgroundColor: secondary.main,
                        color: secondary.light,
                        '& svg': {
                            color: secondary.light
                        }
                    },
                    '&.Mui-selected, &.Mui-selected:hover': {
                        backgroundColor: `${secondary.main} !important`,
                        color: secondary.light,
                        '& svg': {
                            color: secondary.light
                        }
                    }
                },
                selected: {
                    
                }
            }
        },
        MuiTextField: {
            defaultProps: {
                inputProps: {
                    min: 0,
                }
            },
            styleOverrides: {
                root: {
                    marginTop: 0
                },
            },
        },
        MuiInput: {
            styleOverrides: {
                
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderColor: primary.main,
                    }
                },
                input: {
                    padding: '12px 14px',
                },
                notchedOutline: {
                    borderRadius: '3px',
                    border: 'solid 1.5px #DFE2E6'
                },
                focused: {
                    
                }
            },
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    '& .MuiInputLabel-root': {
                        top: -3,
                    }
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    zIndex: 'auto',
                    "&.Mui-focused": {
                        color: primary.main,
                        fontWeight: 600
                    }
                },
                shrink: {
                    color: primary.main,
                    fontWeight: 600
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                contained: {  
                    textTransform: 'uppercase',
                    boxShadow: 'none'
                },
                outlined: {
                    borderWidth: 3,
                    ":hover": { 
                        borderWidth: 3,
                    }
                },
                containedPrimary: {
                    '&:hover': {
                        backgroundColor: secondary.main,
                        color: secondary.light
                    }
                },
                containedSecondary: {
                    '&:hover': {
                        backgroundColor: primary.main,
                        color: primary.light
                    }
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    '& .MuiTabs-flexContainer': {
                        justifyContent: 'space-between'
                    },
                    overflow: 'unset'
                },
                scroller: {
                    overflow: 'unset'
                },
                fixed: {
                    overflow: 'unset'
                },
                indicator: {
                    display: 'none'
                },
                flexContainer : {
                    overflowY: 'clip'
                }
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fbfbfb',
                    padding: '5px 45px',
                    boxShadow: '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)',
                    transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                    transitionProperty: 'box-shadow',
                    transitionDuration: '300ms',
                    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
                    transitionDelay: '0ms',
                    '&.Mui-selected': {
                        backgroundColor: primary.light,
                        borderColor: primary.light,
                    }
                },
                
            },
        },
        
    },
    
    
    // typography: {
    //     fontSize: 12,
    //     h6: {
    //         fontWeight: 700
    //     }
    // },
    // overrides: {
    //     MuiDialog: {
    //         root: {
    //             backgroundColor: 'rgba(164,199,30,0.5)'
    //         }
    //     },
    // },
}
