import { EditorState } from "draft-js"
import { useCallback } from "react"
import { useTextGetSelectionEntity } from "./useTextGetSelectionEntity"

export const useTextGetSelectionData = () => {
    const getEntity = useTextGetSelectionEntity()
    return useCallback((editorState: EditorState) => {
        const entity = getEntity(editorState)
        return entity?.getData() || {}
    }, [])
}
