import '@ephatta/components/dist/index.css'
import { MutationLogState, Root } from '@ephatta/components'
import Routes from './pages/Routes'
import { Theme } from './config/Theme'
import 'react-phone-number-input/style.css'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DndProvider } from "react-dnd"
import { HTML5Backend } from "react-dnd-html5-backend"
import { MutableSnapshot } from 'recoil'

const App = () => { 
    
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DndProvider backend={HTML5Backend}>
                <Root 
                    theme={Theme}
                    loginPath="/login"
                    initialPath="/"
                    routes={Routes}
                    loggedRoutes={loggedRoutes}
                    loggedOutRoutes={loggedOutRoutes}
                    loggedUnAuthorizedRootes={loggedUnAuthorizedRootes}
                    initialState={[
                        {
                            state: MutationLogState,
                            value: true
                        }
                    ]}
                     />
            </DndProvider>
        </LocalizationProvider>
    )
}

const loggedRoutes = '*'

const loggedOutRoutes = [
    '/login',
    '/forgot-password',
    '/establishment/proximity',
    '/register'
]

const loggedUnAuthorizedRootes = [
    '/login',
    '/forgot-password'
]

export default App;
