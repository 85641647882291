import _ from 'lodash'
import { useNavigate } from "react-router-dom"
import { useDidMountEffect } from "hooks"

export const useFilterEffect = <TData = unknown>(formData: TData, pathname: string) => {

    const navigate = useNavigate()

    useDidMountEffect(() => {
        const _formData = _.omitBy(formData as any, (v) => v === null || v === undefined)
        navigate(`${pathname}?${(new URLSearchParams({..._formData}).toString())}`)
    }, [formData])
}
