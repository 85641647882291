

import { EphattaRouteProps } from "@ephatta/components"
import React from "react"

const Login = React.lazy(() => import('./Login'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/login',
        element: <Login />,
    }
]

export default Routes
