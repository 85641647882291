import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { useEditor, useNode } from "@craftjs/core";
import { Box, IconButton, Menu, Typography, TypographyProps } from "@mui/material";
import { TextProperty } from "../property/TextProperty";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container";
import { BreakpointProperty } from "../property/BreakpointProperty";
import _ from "lodash";
import { ContentState, convertToRaw, Editor, EditorState, Modifier } from "draft-js";
import { useDraftDecorator } from "./hooks/useDraftDecorator";
import { useTextEditorState } from "./hooks/useTextEditorState";
import { useUnitWrapper } from "@components/craft-editor/hooks/useUnitWrapper";
import { useBreakpointMatch } from "@components/craft-editor/hooks/useBreakpointMatch";
import Picker from 'emoji-picker-react';
import { EmojiEmotions } from "@mui/icons-material";
import he from "he";
// @ts-ignore
import { blockRenderMap, getCustomStyleMap, extractInlineStyle } from 'draftjs-utils';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { useDidMountEffect } from "@hooks/utils";
import { useTextCustomStyleMap } from "./hooks/useTextCustomStyleMap";
import { migrateContentEditableToDraftJS } from "@components/craft-editor/migration/DraftJsContentEditableMigration";

export const Text = (props: TextProps) => {

    const {
        id,
        setProp,
    } = useNode();

    const textValue = useRef<string>('')
    const { text, defaultText, draggable, fontSize, textDecoration, ...rest } = props;
    const { container } = useBreakpointMatch<TextProps>(props)
    const { enabled, isActive } = useEditor((state, query) => ({
        isActive: query.getEvent('selected').contains(id),
        enabled: state.options.enabled,
    }));

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const openEmoji = useMemo(() => anchorEl !== null, [anchorEl])
    const decorator = useDraftDecorator()
    const saveTimeout = useRef<NodeJS.Timeout>()
    const { editorState, setEditorState } = useTextEditorState()
    const wrapUnit = useUnitWrapper()
    const textCustomStyleMap = useTextCustomStyleMap()

    const decodeHTMLEntity = (tt: string) => {
        let decoded = tt.replace(/&[^;]*;/gmi, (a, b, c) => (b > 0 ? he.decode(a, {
            'isAttributeValue': true
        }) : a))

        return decoded
    }
    
    const getMigratedText = useCallback((text: string) => {
        let result = decodeHTMLEntity(text || '')
        result = migrateContentEditableToDraftJS(result)
        return result
    }, [text])

    const getInitialEditorState = useCallback((text: string) => {
        if (_.isEmpty(text)) {
            return {
                editorState: EditorState.createEmpty(decorator),
                html: text
            }
        }

        const blocksFromHtml = htmlToDraft(text);
        const { contentBlocks, entityMap } = blocksFromHtml;
        let contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
        const editorState = EditorState.createWithContent(contentState, decorator);
        // Save the default html
        const html = getContentHtml(editorState!)
        extractInlineStyle(editorState);
        return {
            _editorState: editorState,
            html
        }
    }, [])

    const getContentHtml = useCallback((editorState: EditorState) => {
        const contentState = editorState.getCurrentContent()
        const rawContentState = convertToRaw(contentState);
        return draftToHtml(rawContentState);
    }, [])

    useEffect(() => {
        if (!editorState) {
            return;
        }

        if (textValue.current === text) {
            return;
        }
        const { _editorState, html }  = getInitialEditorState(text!)
        textValue.current = html
        setEditorState(_editorState)
    }, [text])

    useEffect(() => {
        const _text = getMigratedText(text || defaultText || '')
        const { _editorState, html }  = getInitialEditorState(_text)
        textValue.current = html
        setEditorState(_editorState)
        return () => {
            clearTimeout(saveTimeout.current!)
        }
    }, [])
    
    useDidMountEffect(() => {
        
        if (editorState === undefined) {
            return;
        }

        clearTimeout(saveTimeout.current!)
        saveTimeout.current = setTimeout(() => {
            let html = getContentHtml(editorState)
            html = _.unescape(html)
            if (textValue.current !== html) {
                textValue.current = html
                setProp((newProps: any) => {
                    newProps.text = html
                    props.onChange?.(newProps.text)
                })
            }
        }, 250)
    }, [editorState])

    const handleChange = (editorState: EditorState) => {
        setEditorState(editorState)
    };

    const handleEmojiClick = (emoji: string) => {
        if (editorState === undefined) {
            return;
        }

        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const nextContentState = Modifier.insertText(
            contentState,
            selection,
            emoji
        );
        const _editorState = EditorState.push(
            editorState,
            nextContentState,
            "insert-characters"
        );
        setEditorState(_editorState)
    };

    const getExtendCustomStyleMap =  () => {
        const styleMap = getCustomStyleMap()
        Object.keys(textCustomStyleMap).forEach((key) => {
            const item = textCustomStyleMap[key]
            styleMap[key] = item
        })
        return styleMap
    }

    const textComponent = useMemo(() => (
        <Typography
            {...rest}
            fontSize={fontSize ? wrapUnit(fontSize as string) : undefined}
            width="100%"
            sx={{ textDecoration }}>

            <Editor
                readOnly={!enabled}
                editorState={editorState || EditorState.createEmpty(decorator)}
                blockRenderMap={blockRenderMap}
                customStyleMap={getExtendCustomStyleMap()}
                onChange={handleChange}
                preserveSelectionOnBlur={true}
            />
            {
                editorState &&
                <>
                    {
                        isActive &&
                        <Box position="absolute" top="0" right="0">
                            <IconButton onClick={(e) => setAnchorEl(e.currentTarget)}>
                                <EmojiEmotions />
                            </IconButton>
                            <Menu
                                anchorEl={anchorEl}
                                open={openEmoji}
                                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                onClose={() => setAnchorEl(null)}
                            >
                                <Picker onEmojiClick={(e, data) => handleEmojiClick(data.emoji)} />
                            </Menu>
                        </Box>
                    }
                </>
            }
        </Typography>
    ), [enabled, rest, editorState, fontSize, textDecoration])

    if (!props.draggable) {
        return textComponent
    }

    return (
        <Container container={container} style={{ position: 'relative' }}>
            {textComponent}
        </Container>
    )
}

Text.craft = {
    displayName: 'Text',
    props: {
        draggable: true,
        container: ContainerDefaultProps,
    },
    related: {
        toolbar: TextProperty,
        breakpoint: BreakpointProperty
    },
};

export type TextProps = TypographyProps & {
    text?: string
    defaultText?: string
    draggable?: boolean
    container?: ContainerProps
    editorState?: EditorState
    textDecoration?: string
    onChange?: (value: string) => void
}
