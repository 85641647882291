import { TAdvertEquipmentCreatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useAdvertEquipmentsService } from "./useAdvertEquipmentsService";

export const useAdvertEquipmentUpdate = () => {
    const service = useAdvertEquipmentsService()
    return useEphattaMutation(
        ({ id, payload }: Payload) => service.update(id, payload),
        {
            log: (data, variables) => {
                const [item] = variables?.payload!
                return `Equipement <a href="/equipements">${item.value}(${item.culture})</a> modifié.`
            }
        }
    )
}

type Payload = {
    id: number
    payload: Array<TAdvertEquipmentCreatePayload>
}