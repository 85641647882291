import { TWalletTransferPayload } from "@ephatta/services"
import { useEphattaQuery } from "@hooks/utils"
import _ from "lodash"
import { useWalletServices } from "./useWalletServices"


export const useGetWalletTransfer = (payload: TWalletTransferPayload) => {
    const services = useWalletServices()
  return useEphattaQuery(
    ["GET_WALLET_TRANSFER", payload],
    () => services.getWalletTransfer(payload),
    {
        enabled: !_.isEmpty(payload) && !!payload?.bookingId
    }
  )
}
