import React from 'react'
import {EphattaRouteProps} from "@ephatta/components";

const Dashboard = React.lazy(() => import('./Dashboard'));
const Edit = React.lazy(() => import('./Edit'));

const Routes: EphattaRouteProps[] = [
    {
        path: '/landing/:type',
        element: <Dashboard/>
    },
    {
        path: '/landing/:type/edit',
        element: <Edit/>
    },
    {
        path: '/landing/:type/edit/:id',
        element: <Edit/>
    },
]

export default Routes
