import { TCustomerUpdatePayload } from "@ephatta/services"
import { useEphattaMutation } from "@hooks/utils"
import { useCustomerMiddleOfficeServices } from "./useCustomerMiddleOfficeServices"

export const useCustomerMiddleOfficeUpdate = () => {
  const service = useCustomerMiddleOfficeServices()
  return useEphattaMutation((payload: TCustomerUpdatePayload) => service.update(payload), {
    log: (data, variables) => {
      return `L'utilisateur <a href="/mo-user">${variables?.firstname} ${variables?.lastname}<${variables?.email}></a> a été modifié.`
    }
  })
}
