import React from 'react'

import {EphattaRouteProps} from "@ephatta/components";

const Adverts = React.lazy(() => import('./Adverts'));
const Equipements = React.lazy(() => import('./Equipements'));
const Environments = React.lazy(() => import('./Environments'));
const Details = React.lazy(() => import('./Details'));

const Routes: EphattaRouteProps[] = [
    {
        path: '/adverts',
        element: <Adverts/>
    },
    {
        path: 'advert/:id',
        element: <Details/>
    },
    {
        path: '/equipements',
        element: <Equipements />
    },
    {
        path: '/environments',
        element: <Environments />
    },
]

export default Routes
