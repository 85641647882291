import { TWalletTransferPayload } from "@ephatta/services"
import { useEphattaMutation } from "@hooks/utils"
import { useWalletServices } from "./useWalletServices"

export const useWalletTransfer = () => {
    const services = useWalletServices()
  return useEphattaMutation(
    (payload: TWalletTransferPayload) => services.walletTransfer(payload),
    {
      showSuccessMessage: true,
      successMessage:"Operation effectué",
      errorMessage:"Une erreur est survenue durant l'opération",
      log(data?, variables?) {
        return `Transfert de ${variables?.amount || 0}€ du portefeuille ${variables?.debitedWalletId} vers ${variables?.creditedWalletId} ${variables?.bookingId ? `pour la réservation ${variables?.bookingId}` : ''}`
      },
    }
  )
}
