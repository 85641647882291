import { Button, ButtonTypeMap, Grid, Typography } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import React, { useCallback, FormEvent } from "react"


/**
 * Form wrapper for filter
 */
export const FilterWrapper = (props: Props) => {

    const handleSubmit = useCallback((e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        props.onSubmit?.()
    }, [props.onSubmit])

    return (
        <form onSubmit={handleSubmit}>
            {
                props.title &&
                <Typography variant="h6" color="secondary" className="mb-30">{props.title}</Typography>
            }

            { props.children }

            <Grid container>
                <Grid xs={12}>
                    <Button 
                        color="secondary" 
                        variant="contained" 
                        className="mt-30 float-right" 
                        {...props.buttonSubmit} 
                        type="submit">
                        { props.buttonSubmit?.label || props.buttonSubmit?.children || 'Rechercher' }
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

type Props = {
    title?: string
    buttonSubmit?: DefaultComponentProps<ButtonTypeMap<{}, "button">> & {
        label?: string
    }
    children: React.ReactNode
    onSubmit?: () => void 
}
