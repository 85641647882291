import React, { useEffect, useState } from "react";
import { Element, Frame, Frame as FrameProps, useEditor } from "@craftjs/core";
import { Box, Paper, ThemeOptions, Container as MUIContainer, Breakpoint, Theme, CircularProgress } from "@mui/material"
import { Container } from "./components/elements/Container";
import { Property } from "./components/property/Property";
import { CraftEditorTheme } from "./CraftEditorTheme";
import { useEditorEnabled } from "./hooks/useEditorEnabled";
import { useScreenBreakPoint } from "./hooks/useScreenBreakPoint";
import { useDebounce } from "hooks";
import { makeStyles } from "@mui/styles";
import { Toolbar, ToolbarProps } from "./components/toolbar/Toolbar";
import { ToolboxWraper } from "./components/toolbox/ToolboxWraper";

export const CraftEditorDesigner = ({ theme, isDesigner, ToolbarProps, ...props }: Props) => {

    const [initialized, setInitialized] = useState(props.enabled !== false)
    const { actions } = useEditor();
    const enabled = useEditorEnabled()
    const debounce = useDebounce()
    const [breakpoint] = useScreenBreakPoint()
    const classes = useStyle()

    useEffect(() => {
        if (props.enabled === false) {
            debounce(() => {
                actions.setOptions((options) => (options.enabled = (props.enabled !== false)))
                setInitialized(true)
            }, 500)
        }
    }, [])

    const wrapContainer = (children: React.ReactElement) => {

        if (!enabled && !isDesigner) {
            return children
        }

        return (
            <MUIContainer maxWidth={breakpoint as Breakpoint}>
                {children}
            </MUIContainer>
        )
    }

    if (!initialized) {
        return (
            <Box width="100%" p="30px" display="flex" alignItems="center" justifyContent="center">
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box display="flex" flexDirection="column" gap="30px">
            {
                props.hideToolbar !== true &&
                <Paper className={classes.toolbar}>
                    <Toolbar {...ToolbarProps} />
                </Paper>
            }
            <Box display="flex" gap="20px">
                {
                    enabled &&
                    <Box className={classes.toolbox}>
                        <ToolboxWraper />
                    </Box>
                }
                <Box display="flex" justifyContent="center" flex="1" key={enabled ? 'item-enabled' : 'item-disabled'}>
                    {
                        wrapContainer(
                            <CraftEditorTheme theme={theme}>
                                <Frame {...props}>
                                    <Element
                                        is={Container as any}
                                        padding={30}
                                        style={{ margin: 0 }}
                                        container={{
                                            flexDirection: 'column',
                                            className: "craft-editor-root",
                                            elevation: 0,
                                            overflow: 'hidden'
                                        }}
                                        width="100%"
                                        isHoverable={false}
                                        canvas>
                                    </Element>
                                </Frame>
                            </CraftEditorTheme>
                        )
                    }
                </Box>
                {
                    enabled &&
                    <Box className={classes.property}>
                        <Paper sx={{ padding: '30px' }}>
                            <Property />
                        </Paper>
                    </Box>
                }

            </Box>
        </Box>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    toolbar: {
        display: "flex",
        position: "sticky",
        top: 60,
        zIndex: 99,
        padding: '10px 20px'
    },
    toolbox: {
        position: "sticky",
        top: 160,
        maxHeight: "80vh",
    },
    property: {
        position: "sticky",
        top: 160,
        maxHeight: "80vh",
        width: 320,
        overflowY: "auto",
    }
}))

type Props = FrameProps & {
    theme?: ThemeOptions
    enabled?: boolean
    isDesigner?: boolean
    hideToolbar?: boolean
    ToolbarProps: ToolbarProps
}
