
import { makeStyles } from "@mui/styles";
import React from "react"
import { BreakpointProperty } from "../property/BreakpointProperty";
import { TextProperty } from "../property/TextProperty";
import { ContainerProps } from "./Container"
import { Text } from "./Text"

export const List = (props: ListProps) => {

    const classes = useStyle()

    const getContent = (value: string) => {
        const match = value.match(new RegExp(`(?<=<(ol|ul)>)(.*?)(?=</(ol|ul)>)`))
        return match?.[2] || value
    }

    return (
        <Text
            {...props}
            draggable
            key={`craft-editor-list-${props.type}`}
            className={`${classes.craftEditorText}`}
            defaultText={`<${props.type}>${getContent(props.text) || ''}</${props.type}>`}
            text={undefined}
        />
    )
}

const useStyle = makeStyles(() => ({
    craftEditorText: {
        '& ul, ol': {
            listStylePosition: 'inside',
            '& .public-DraftStyleDefault-ltr': {
                display: 'inline'
            }
        }
    }
}))

export const ListDefaultProps = {
    type: 'ul',
}

List.craft = {
    displayName: 'Button',
    props: ListDefaultProps,
    related: {
        toolbar: TextProperty,
        breakpoint: BreakpointProperty
    },
};

export type ListProps = {
    type: 'ul' | 'ol'
    text: string
    container?: ContainerProps['container']
}
