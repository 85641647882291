import { TAdvertSubscription } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useAdvertSubscriptionService } from "./useAdvertSubscriptionService";

export const useAdvertSubscriptionUpdate = () => {
    const service = useAdvertSubscriptionService()
    return useEphattaMutation(
        ({ id, payload }: Payload) => service.update(id, payload!), {
            log: (data, variables) => {
              return `Abonnement <a href="/subscribe/${variables?.id}">${variables?.payload.advertSubscriptionDetails?.find((item) => item.culture === 'fr')?.title}</a> a été modifié.`
            }
        }
    )
}

type Payload = {
    id: number
    payload: Partial<TAdvertSubscription>
}