import { TDynamicContentCreatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useDynamicContentService } from "./useDynamicContentService";

export const useDynamicContentCreate = () => {
    const service = useDynamicContentService()
    return useEphattaMutation(
        (payload: TDynamicContentCreatePayload) => service.create(payload),
        {
            log: (data, variables) => {
                return `Contenu dynamique <a href="/dynamic-content">${variables?.value}</a> ajouté.`
            }
        }
    )
}