import { EditorState } from "draft-js"
import { useCallback } from "react"

export const useTextGetSelectionEntity = () => {
    return useCallback((editorState: EditorState) => {
        const contentState = editorState.getCurrentContent();
        const startKey = editorState.getSelection().getStartKey();
        const startOffset = editorState.getSelection().getStartOffset();
        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
        const entityKey = blockWithLinkAtBeginning.getEntityAt(startOffset);

        if (entityKey) {
            return contentState.getEntity(entityKey);
        }
        return null
    }, [])
}
