import { useEditor, useNode } from "@craftjs/core";
import { Box, useTheme } from "@mui/material"
import React, { createRef, useCallback, useEffect } from "react";
import ReactDOM from 'react-dom';
import { Indicator } from "./Indicator";

export const RenderNode = ({ render, ...restProps }: Record<string, unknown>) => {

    const { dom } = useNode((node) => ({
        dom: node.dom,
    }));

    const { id } = useNode();
    const { active, hover } = useEditor((_, query) => ({
        hover: query.node(id).isHovered(),
        active: query.node(id).isSelected()
    }));

    const theme = useTheme()
    const currentRef = createRef<HTMLDivElement>();

    useEffect(() => {
        if (dom) {
            if (hover) {
                dom.style.outline = `1px dashed ${theme.palette.secondary.main}`
            }

            if (active) {
                dom.style.outline = `2px solid ${theme.palette.secondary.main}`
            }
            
            if (!active && !hover) {
                dom.style.outline = 'none'
            }
        }
    }, [dom, id, active, hover]);

    const getPos = useCallback((dom: HTMLElement) => {
        const { top, left, right, bottom } = dom
            ? dom.getBoundingClientRect()
            : { top: 0, left: 0, bottom: 0, right: 0 };

        return {
            top: `${top > 0 ? top : bottom}px`,
            left: `${left}px`,
            right: `${right}px`
        };
    }, [currentRef]);

    const scroll = useCallback(() => {

        const { current: currentDOM } = currentRef;

        if (!currentDOM) return;
        const { top, right } = getPos(dom!);
        currentDOM.style.top = top;
        currentDOM.style.left = right;
    }, [dom, getPos]);

    useEffect(() => {
        document.addEventListener('scroll', scroll);

        return () => {
            document.removeEventListener('scroll', scroll);
        };
    }, [scroll]);

    return (
        <>
            {
                (hover || active)
                    ? ReactDOM.createPortal(
                        <Box
                            display="flex"
                            position="fixed"
                            bgcolor="white"
                            top={parseInt(getPos(dom!).top)}
                            left={getPos(dom!).right}
                            marginTop="-35px"
                            zIndex="900"
                            ref={currentRef}>
                            <Indicator />
                        </Box>,
                        document.querySelector('.page-container')!
                    )
                    : null
            }
            {render}
        </>
    )
}
