import { EphattaRouteProps } from '@ephatta/components';
import SubscriptionPageRoutes from './subscription/Routes'
import SubscribePageRoutes from './subscribe/Routes'

const Routes: Array<EphattaRouteProps> = [
    ...SubscribePageRoutes,
    ...SubscriptionPageRoutes,
]

export default Routes
