import React, { CSSProperties } from "react";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container"
import { ImageProperty } from "../property/ImageProperty";
import { BreakpointProperty } from "../property/BreakpointProperty";
import { useBreakpointMatch } from "components/craft-editor/hooks/useBreakpointMatch";

export const Image = (props: ImageProps) => {

    const { src, alt, title, objectFit, container } = useBreakpointMatch<ImageProps>(props)

    return (
        <Container container={container}>
            <img 
                src={src} 
                alt={alt === '' ? process.env.REACT_APP_NAME : alt} 
                title={title} 
                style={{ 
                    minHeight: 80, 
                    maxWidth: '100%',
                    width: '100%',
                    height: '100%',
                    maxHeight: '100%',
                    objectFit
                }} 
                />
        </Container>
    )
}

Image.craft = {
    displayName: 'Image',
    props: {
        src: '',
        alt: process.env.REACT_APP_NAME,
        title: '',
        objectFit: 'fill',
        container: {
            ...ContainerDefaultProps,
            display: 'block',
            padding: '0',
            maxWidth: '100%',
            maxHeight: '100%'
        }
    },
    related: {
      toolbar: ImageProperty,
      breakpoint: BreakpointProperty
    },
};

export type ImageProps = {
    src: string
    alt?: string
    title?: string
    objectFit?: CSSProperties['objectFit']
    container?: ContainerProps['container']
}
