import { useEphattaMutation } from "@hooks/utils"
import { useTranslation } from "react-i18next"
import { useAdminAdvertBookingService } from "./useAdminAdvertBookingService"

export const useAdminBookingCancellation = () => {
  const {t} = useTranslation()
    const services = useAdminAdvertBookingService()
  return useEphattaMutation(
    (id: string) => services.adminCancellation(id),{
      successMessage: t("Booking.actions.updated.cancelledMessage"),
      log(data, variables) {
        return `Réservation <a href="/booking/${variables}">${variables}</a> annulée`
      },
    }
  )
}
