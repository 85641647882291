import { useEphattaQuery } from "@hooks/utils"
import { useAdvertBookingService } from "./useAdvertBookingService"

export const useListBookingStatus = () => {
    const services = useAdvertBookingService()
  return useEphattaQuery(
    ["Booking.Status"],
    () => services.listBookingStatus()
  )
}
