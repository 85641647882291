import { TLandingPageCreatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useLandigPageType } from "./useLandigPageType";
import { useLandingPageService } from "./useLandingPageService";

export const useLandingPageCreate = () => {
    const service = useLandingPageService()
    const getLandingPageType = useLandigPageType()
    return useEphattaMutation(
        (payload: TLandingPageCreatePayload) => service.create(payload),
        {
            log: (data, variables) => {
                return `Landing page ${getLandingPageType(variables?.type || '')} <a href="/landing/content/edit/${data?.id}">${variables?.title}</a> ajouté.`
            }
        }
    )
}
