
import React from "react";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container"
import YouTube from 'react-youtube';
import { VideoYoutubeProperty } from "../property/VideoYoutubeProperty";
import { makeStyles } from "@mui/styles";
import { BreakpointProperty } from "../property/BreakpointProperty";
import { useEditorEnabled } from "@components/craft-editor/hooks/useEditorEnabled";
import { Box } from "@mui/material";
import { useBreakpointMatch } from "@components/craft-editor/hooks/useBreakpointMatch";

export const VideoYoutube = ({ videoId, ...props }: VideoYoutubeProps) => {

    const classes = useStyle()
    const enabled = useEditorEnabled()
    const { container } = useBreakpointMatch<Omit<VideoYoutubeProps, 'videoId'>>(props)

    return (
        <Container container={container} style={{ display: 'flex', position: 'relative' }}>
            <>
                <YouTube
                    videoId={videoId}
                    className={classes.videoYoutubeContainer}
                    containerClassName={classes.videoYoutube}
                />
                {
                    enabled &&
                    <Box sx={{ position: 'absolute', inset: "0", zIndex: 99 }} >

                    </Box>
                }
            </>
        </Container>
    )
}

const useStyle = makeStyles(() => ({
    videoYoutube: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'stretch',
        alignItems: 'stretch',
        flex: 1,
        width: '100%',
        height: '100%'
    },
    videoYoutubeContainer: {
        width: '100%',
        height: '100%'
    }
}))

VideoYoutube.craft = {
    displayName: 'Video Youtube',
    props: {
        videoId: '',
        container: ContainerDefaultProps
    },
    related: {
        toolbar: VideoYoutubeProperty,
        breakpoint: BreakpointProperty
    },
};

export type VideoYoutubeProps = {
    videoId: string

    container?: ContainerProps['container']
}
