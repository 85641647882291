import React, { useCallback } from "react";
import { useNode } from "@craftjs/core";
import { TextField } from "@mui/material"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { ContainerProperty } from "./ContainerProperty";

export const VideoYoutubeProperty = () => {

    const {
        actions: { setProp },
        videoId,
    } = useNode((node) => ({
        videoId: node.data.props.videoId,
    }));

    const getId = useCallback((url: string) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return (match&&match[7].length==11)? match[7] : '';
    }, [])

    return (
        <>
            
            <ContainerProperty />

            <PropertyItemTitle>Lien</PropertyItemTitle>
            <TextField 
                label="Lien vers la video" 
                fullWidth value={videoId || ''} 
                onChange={(e) => setProp((props: any) => props.videoId = getId(e.target.value)) } 
                />
        </>
    )
}
