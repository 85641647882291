import { YouTube } from "@mui/icons-material"
import React, { useMemo } from "react"
import { VideoVimeo } from "../components/elements/VideoVimeo"
import { VideoYoutube } from "../components/elements/VideoYoutube"
import { VimeoIcon } from "../icon/VimeoIcon"
import { ToolboxItemProps } from "./useToolbox"

export const useToolboxVideos = () => {

    return useMemo((): Array<ToolboxItemProps> => ([
        {
            label: 'Youtube',
            icon: <YouTube />,
            element: VideoYoutube,
        },
        {
            label: 'Vimeo',
            icon: <VimeoIcon />,
            element: VideoVimeo
        }
    ]), [])
}

