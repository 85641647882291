import { TAdvertEnvironmentCreatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useAdvertEnvironmentService } from "./useAdvertEnvironmentService";

export const useAdvertEnvironmentCreate = () => {
    const service = useAdvertEnvironmentService()
    return useEphattaMutation(
        (payload: Array<TAdvertEnvironmentCreatePayload>) => service.create(payload),
        {
            log: (data, variables) => {
                const [item] = variables!
                return `Environnement <a href="/environments">${item.value}(${item.culture})</a> ajouté.`
            }
        }
    )
}