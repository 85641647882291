import React from "react"
import { CopyAll, Delete, OpenWith } from "@mui/icons-material"
import { Box, Divider, IconButton, Theme } from "@mui/material"
import { useEditor, useNode } from "@craftjs/core";
import { makeStyles } from "@mui/styles";
import _ from "lodash";
import { useDuplicateNode } from "@components/craft-editor/hooks/useDuplicateNode";

export const Indicator = () => {

    const { id } = useNode();
    const { actions, query } = useEditor();

    const classes = useStyle()

    const {
        moveable,
        deletable,
        node,
        connectors: { drag },
    } = useNode((node) => ({
        isHover: node.events.hovered,
        dom: node.dom,
        name: node.data.custom.displayName || node.data.displayName,
        moveable: query.node(node.id).isDraggable(),
        deletable: query.node(node.id).isDeletable(),
        parent: node.data.parent,
        props: node.data.props,
        fillSpace: node.data.props.fillSpace,
        node: node
    }));

    const { isRootNode } = useEditor((state, query) => {
        return {
            isRootNode: query.node(id).isRoot(),
        };
    });

    const duplicateNode = useDuplicateNode()

    return (
        <>
            {
                !isRootNode &&
                <Box display="flex" className={classes.indicator}>
                    {
                        moveable &&
                        <IconButton
                            title="Déplacer"
                            ref={ref => drag(ref!)}>
                            <OpenWith />
                        </IconButton>
                    }

                    <IconButton 
                        title="Dupliquer"
                        onClick={() => duplicateNode(node.id, node.data.parent)}>
                        <CopyAll />
                    </IconButton>
                    
                    <Divider orientation="vertical" />
                    {
                        deletable &&
                        <IconButton 
                            title="Supprimer"
                            onClick={() => actions.delete(id)}>
                            <Delete />
                        </IconButton>
                    }
                </Box>
            }
        </>
    )
}

const useStyle = makeStyles((theme: Theme) => ({
    indicator: {
        position: "absolute",
        right: '0px',
        zIndex: 900,
        backgroundColor: theme.palette.secondary.main,
        display: 'flex',
        '& svg': {
            fontSize: '18px',
            color: theme.palette.secondary.light,
        }
    },
    cornerIndicatorBoundRow: {
        '&:nth-child(1)': {
            left: '50%',
            top: '-5px',
            transform: 'translateX(-50 %)',
        },
        '&:nth-child(2)': {
            display: 'none'
        },
        '&:nth-child(3)': {
            left: '50%',
            bottom: '-5px',
            transform: 'translateX(-50 %)',
        },
        '&:nth-child(4)': {
            display: 'none'
        }
    },
    cornerIndicatorBoundColumn: {
        '&:nth-child(1)': {
            top: '50 %',
            left: '-5px',
            transform: 'translateY(-50 %)',
        },
        '&:nth-child(2)': {
            display: 'none'
        },
        '&:nth-child(3)': {
            left: '50%',
            bottom: '-5px',
            transform: 'translateY(-50 %)',
        },
        '&:nth-child(4)': {
            display: 'none'
        }
    },
    cornerIndicator: {
        '& span': {
            position: 'absolute',
            width: '5px',
            height: '5px',
            background: theme.palette.primary.light,
            borderRadius: '100%',
            display: 'block',
            boxShadow: '0px 0px 12px -1px rgba(0, 0, 0, 0.25)',
            pointerEvent: 'none',
            border: `2px solid ${theme.palette.secondary.main}`,
            '&:nth-child(1)': {
                left: '-5px',
                top: '-5px'
            },
            '&:nth-child(2)': {
                right: '-5px',
                top: '-5px',
                display: 'block'
            },
            '&:nth-child(3)': {
                left: '-5px',
                bottom: '-5px',
            },
            '&:nth-child(4)': {
                bottom: '-5px',
                right: '-5px',
                display: 'block'
            }
        },
    }

}))

