import React, { useState, useCallback, ChangeEvent, useEffect, useRef } from 'react'
import { Button, useMediaQuery, useTheme } from '@mui/material'
import { PictureCropper, PictureCropperProps } from './PictureCropper';
import { GenericDialog, useTranslations } from '../..';
import heic2any from "heic2any";
import { TImageEditCoordonates } from '@ephatta/services';

export const PictureUploader: React.FC<PictureUploaderProps> = (props) => {

    const [
        UPLOAD_PICTURE
    ] = useTranslations(i18n)
    const muiTheme = useTheme();
    const fullScreen = useMediaQuery(muiTheme.breakpoints.down('md'));
    const inputRef = useRef<HTMLInputElement>(null)
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<string | undefined>(props.value || props.defaultValue)

    const [fileName, setFileName] = useState('')

    useEffect(() => {
        if (props.value !== value) {
            setValue(props.value)
        }
    }, [props.value])

    const readFile = useCallback((file: Blob): Promise<any> => {
        return new Promise(resolve => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }, [])

    
    const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
        props?.onSelectionChange?.(e.target.files)
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0]
            let imageDataUrl ;
            if (
                file.type.toLowerCase() === "image/heic"  ||
                file.name.toLowerCase().includes(".heic") || 
                file.type.toLowerCase() === "image/heif"  ||
                file.name.toLowerCase().includes(".heif") 
              ) {
                const newImage = await heic2any({ blob: file, toType: "image/jpg", quality: 0.1 })
                imageDataUrl = await readFile(newImage as File)
                setValue(imageDataUrl);
              }else{
                imageDataUrl = await readFile(file)
                setValue(imageDataUrl);
              }
              setFileName(file.name.replace(/(hei(c|f))$/i,'jpg'))
              setOpen(true);
        }
    }

    const handleSave = (val: string, coordinates?: TImageEditCoordonates) => {
        setValue(val)
        if(props?.backend){
            props.onChange?.(val, fileName, coordinates)
        }else{
            props.onChange?.(val, fileName)
        }
        setOpen(false)
    }

    const handleClose = () => {
        setOpen(false);
        //setCroppedImage(null);
    };

    return (
        <>
            <input 
                type="file" 
                ref={inputRef}
                accept="image/*"
                style={{ display: 'none' }}
                onChange={handleFileChange}
                />

            <div onClick={() =>!props.isLoading && inputRef?.current?.click()}>
                {
                    props.children || <Button>{UPLOAD_PICTURE}</Button>
                }
            </div>

            {
                <GenericDialog
                    fullScreen={fullScreen}
                    open={open}
                    title={UPLOAD_PICTURE}
                    onClose={handleClose}
                    btnOk={false}
                    btnCancel={false}
                    maxWidth="lg"
                    fullWidth
                    >
                    <PictureCropper
                        value={value}
                        onSave={handleSave}
                        backend={props.backend}
                        {...props.PictureCropperProps}
                        />
                </GenericDialog>
            }
        </>
    )
}

const i18n = [
    'Generic.PictureCropper.UploadPicture.label'
]

type PictureUploaderProps = {
    id?: string
    value?: string
    defaultValue?: string
    children?: React.ReactNode
    onChange?: (value: string, fileName : string, coordinates?: TImageEditCoordonates) => void
    onSelectionChange?: (files: FileList | null) => void
    PictureCropperProps?: Partial<PictureCropperProps>
    isLoading?: boolean
    backend?: boolean
}



