import { TAdvertEnvironmentCreatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useAdvertEnvironmentService } from "./useAdvertEnvironmentService";

export const useAdvertEnvironmentUpdate = () => {
    const service = useAdvertEnvironmentService()
    return useEphattaMutation(
        ({ id, payload }: Payload) => service.update(id, payload),
        {
            log: (data, variables) => {
                const [item] = variables?.payload!
                return `Equipement <a href="/environments">${item.value}(${item.culture})</a> modifié.`
            }
        }
    )
}

type Payload = {
    id: number
    payload: Array<TAdvertEnvironmentCreatePayload>
}