import { EphattaRouteProps } from '@ephatta/components';
import StaticPageRoutes from './static-page/Routes'
import LandingPageRoutes from './landing-page/Routes'
import AnnouncePageRoutes from './announce-page/Routes'
import DynamicContentRoutes from './dynamic-content/Routes'

const Routes: Array<EphattaRouteProps> = [
    ...StaticPageRoutes,
    ...LandingPageRoutes,
    ...AnnouncePageRoutes,
    ...DynamicContentRoutes
]

export default Routes
