import React, { useCallback, useMemo, useState } from "react";
import { MenuItem, Select, TextField } from "@mui/material"
import { useTextVariants } from "../../hooks/useTextVariants";
import { PropertyItemTitle } from "./PropertyItemTitle"
import { ContainerProperty, ContainerPropertyProps } from "./ContainerProperty";
import { useTextAligns } from "components/craft-editor/hooks/useTextAligns";
import { PropertyContainer } from "./PropertyContainer";
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint";
import { TextColorProperty } from "./TextColorProperty";
import { TextToolsProperty } from "./TextToolsProperty";
import { useTextFontFamily } from "./hooks/useTextFontFamily";
import { useUnitWrapper } from "@components/craft-editor/hooks/useUnitWrapper";
import { useTextTransforms } from "@components/craft-editor/hooks/useTextTransforms";
import { CraftEditorParametersPropertiesTextVariantOption } from "@components/craft-editor/CraftEditor";
import { useTextEditorFontChange } from "../elements/hooks/useTextEditorFontChange";
import { useTextFontSizes } from "@components/craft-editor/hooks/useTextFontSizes";
import { TextFontSizeDialog, TextFontSizeDialogValue } from "../dialog/TextFontSizeDialog";
// @ts-ignore
import { getSelectionInlineStyle } from 'draftjs-utils';
import { useTextEditorState } from "../elements/hooks/useTextEditorState";

export const TextProperty = (props: TextPropertyProps) => {

    const variants = useTextVariants()
    const fontSizes = useTextFontSizes()
    const textAligns = useTextAligns()
    const textTransforms = useTextTransforms()
    const { getValue, setValue } = useBreakpoint()
    const fontFamilyOptions = useTextFontFamily()
    const wrapUnit = useUnitWrapper()
    const [fontSizeValue, setFontSizeValue] = useState<TextFontSizeDialogValue>()
    const { editorState } = useTextEditorState()

    const handleFontChange = useTextEditorFontChange()

    const handleTextChange = useCallback((key: string, value?: any) => {
        setValue(key, value, false)
    }, [])

    const handleVariantChange = useCallback((option?: CraftEditorParametersPropertiesTextVariantOption) => {
        if (!option) {
            return;
        }
        setValue((props: any) => {
            Object.keys(option).forEach((key) => {
                const item = option[key]
                props[key] = item
            })
            props.variant = option.value

            return props
        }, undefined, false)
    }, [])

    const fontData = useMemo(() => {
        return getSelectionInlineStyle(editorState)
    }, [editorState]) 
    
    const fontSize = useMemo(() => {
        return fontData?.fontSize ? fontData?.fontSize?.replace('px', '') : getValue('fontSize')?.replace('px', '')
    }, [fontData, getValue])

    const fontSizesOptions = useMemo(() => {
        const exist = fontSizes.some((item) => item.value === fontSize)
        if (!exist) {
            return [...fontSizes, { label: fontSize, value: parseInt(fontSize) }].sort((a, b) => (a.value as number)! - (b.value as number)!)
        }
        return fontSizes
    }, [fontSize, fontData])

    const handleFontSizeChange = (value: string) => {
        if (value === 'NEW') {
            setFontSizeValue({ fontSize: fontSize })
            return;
        }

        handleFontChange('fontSize', wrapUnit(value))
        fontSizeValue && setFontSizeValue(undefined)
    }

    return (
        <>
            <TextToolsProperty />

            <PropertyContainer title="Texte">
                <PropertyItemTitle>Variant</PropertyItemTitle>
                <Select
                    fullWidth
                    value={getValue('variant')}
                    onChange={(e) => handleVariantChange(variants.find((item) => item.value === e.target.value))}>
                    {
                        variants.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </Select>

                <PropertyItemTitle>Police</PropertyItemTitle>
                <Select
                    fullWidth
                    value={fontData?.fontFamily ? fontData?.fontFamily?.replaceAll('"', '') : getValue('fontFamily')}
                    onChange={(e) => handleFontChange('fontFamily', e.target.value)}>
                    {
                        fontFamilyOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </Select>

                <TextColorProperty />

                <PropertyItemTitle>Taille</PropertyItemTitle>
                <Select
                    fullWidth
                    value={fontSize}
                    onChange={(e) => handleFontSizeChange(e.target.value)}>
                    <MenuItem value="NEW">Ajouter</MenuItem>
                    {
                        fontSizesOptions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </Select>

                <PropertyItemTitle>Alignement</PropertyItemTitle>
                <Select
                    fullWidth
                    value={getValue('textAlign')}
                    onChange={(e) => handleTextChange('textAlign', e.target.value)}>
                    {
                        textAligns.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </Select>

                <PropertyItemTitle>Interligne</PropertyItemTitle>
                <TextField
                    fullWidth
                    value={getValue('lineHeight')}
                    onChange={(e) => handleTextChange('lineHeight', e.target.value)}
                />

                <PropertyItemTitle>Transformation du texte</PropertyItemTitle>
                <Select
                    fullWidth
                    value={fontData?.textTransform ? fontData?.textTransform : getValue('textTransform')}
                    onChange={(e) => handleFontChange('textTransform', e.target.value)}>
                    {
                        textTransforms.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </Select>
            </PropertyContainer>

            {
                props.hideContainerProperty !== true &&
                <ContainerProperty
                    hideAlignment
                    hideDirection
                    {...props}
                />
            }

            {
                fontSizeValue &&
                <TextFontSizeDialog
                    open={fontSizeValue !== undefined}
                    onClose={() => setFontSizeValue(undefined)}
                    value={fontSizeValue!}
                    onSubmit={(value) => handleFontSizeChange(value.fontSize)}
                    />
            }
        </>

    )
}

type TextPropertyProps = ContainerPropertyProps & {
    hideContainerProperty?: boolean
}
