import { useEphattaMutation } from "@hooks/utils"
import { useCustomerService } from "./useCustomerService"

export const useCustomerEnable = () => {
  const service = useCustomerService()
  return useEphattaMutation(
    ({ email, applicationId }: enableType) => service.activateUser(email, applicationId),
    {
      log: (data, variables) => `Activer l'utilisateur <${variables?.email}>`
    }
  )
}
type enableType = {
  email: string, applicationId: number
}