import React, { useRef, useEffect, useState } from "react";
import ReactDOM from 'react-dom'
// @ts-ignore
import mapboxgl, { LngLatLike } from "!mapbox-gl";
import MapboxLanguage from '@mapbox/mapbox-gl-language';

import _ from "lodash";
import { IconButton, Theme, useTheme } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useMapboxCreateGeoJSONCircle } from "./hooks/useMapboxCreateGeoJSONCircle";
import { makeStyles } from "@mui/styles";

const MapBox = ({ coordinate, currentCoordinate, currentZoom, isInteractive, circleMarker, centerOnLastResult, ...props }: Props) => {
    const mapContainerRef = useRef<HTMLDivElement>(null);
    const popupRef = useRef<HTMLDivElement>(null)
    const [zoom, setZoom] = useState(currentZoom ? currentZoom : 12);
    const [initialData, setInitialData] = useState<LngLatLike>(currentCoordinate ? currentCoordinate : [9.1, 42.2]);
    const [coordinates, setCoordinates] = useState<Array<LngLatLike>>(props?.coordinates || [])
    const [mapInstance, setMapInstance] = useState<mapboxgl.Map>()
    const markers = useRef<Array<mapboxgl.Marker>>([])
    const theme = useTheme()
    const createGeoJSONCircle = useMapboxCreateGeoJSONCircle()
    const classes = useStyles()
    
    useEffect(()=> {
        if(currentZoom !== zoom && _.isNumber(currentZoom)){
            setZoom(currentZoom)
        }
    },[currentZoom])
    
    useEffect(()=>{
        if (!mapInstance) {
            return;
        }

        if(currentZoom && currentZoom !== zoom){
            setZoom(currentZoom)
        }
    },[currentZoom])
    
    useEffect(() => {

        if (!mapContainerRef.current) {
            return
        }
        

        // Initialize map when component mounts
        mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_GL_TOKEN!
        const map = new mapboxgl.Map({
            container: mapContainerRef.current,
            style: "mapbox://styles/mapbox/streets-v11",
            center: initialData,
            zoom: zoom,
            interactive: isInteractive,
            ...(Boolean(props?.maxZoom) ? { maxZoom: props.maxZoom } : {}),
        });

        map.addControl(new MapboxLanguage({
            defaultLanguage: 'fr',
        }));

        if (isInteractive) map.addControl(new mapboxgl.NavigationControl(), "top-right");
        setMapInstance(map)

        return () => map.remove();
    }, [])

    useEffect(() => {
        if (!mapInstance) {
            return;
        }

        if (currentCoordinate) {
            // new mapboxgl.Marker({ color: theme.palette.primary.main }).setLngLat(currentCoordinate).addTo(mapInstance);
            mapInstance.flyTo({ center: currentCoordinate, zoom })
        }

        if (coordinate) {
            setInitialData(coordinate);
            new mapboxgl.Marker({ color: theme.palette.primary.main }).setLngLat(coordinate).addTo(mapInstance);
        }

        props.onMapInstance?.(mapInstance)
    }, [mapInstance , currentCoordinate])

    useEffect(() => {

        if (!mapInstance) {
            return;
        }

        if(coordinates){
            markers.current.forEach((item) => item.remove())
        }

        if (!_.isEmpty(coordinates)) {
            setInitialData(coordinates[0]);
            coordinates?.map(coordinateItem => {
                const el = document.createElement("div")
                el.className = classes.markera
                
                const marker = new mapboxgl.Marker(circleMarker ? el : { color: theme.palette.primary.main })
                    .setLngLat(coordinateItem);
                const component = props.onRenderPopup?.(coordinateItem)
                if (component) {
                    const popupElt = document.createElement('div');
                    ReactDOM.render(component, popupElt)
                    const popup = new mapboxgl.Popup({ maxWidth: "auto" }).setDOMContent(popupElt);
                    marker.setPopup(popup)
                }

                marker.addTo(mapInstance);
                markers.current.push(marker)

                centerOnLastResult && mapInstance.setCenter(coordinateItem)
                if (props.isHightLightPosition) {
                    const polygon = createGeoJSONCircle(marker.getLngLat().toArray(), 5)
                    mapInstance.on('styledata', () => {
                        mapInstance.addSource("polygon", polygon as any)
                        mapInstance.addLayer({
                            "id": "polygon",
                            "type": "fill",
                            "source": "polygon",
                            "layout": {},
                            "paint": {
                                "fill-color": theme.palette.secondary.main,
                                "fill-opacity": 0.6
                            }
                        });
                    })
                }
                
                try {
                    
                } catch (e) {
                    console.log(e)
                }
            })
        }
    }, [coordinates, mapInstance])

    useEffect(() => {

        if (!_.isEqual(props.coordinates, coordinates)) {
            setCoordinates(props.coordinates || [])
        }

    }, [props.coordinates])

    return (
        <>
            <div
                {...props}
                className={`map-container ${props.className}`}
                style={{ width: "300px", height: "300px", marginTop: "20px", ...props.style }}
                ref={mapContainerRef}
            />
            <div ref={popupRef}>
            </div>
            {
                props?.hasCloseButton && (
                    <IconButton
                        style={{
                            position: 'absolute',
                            top: 5,
                            left: 0,
                            backgroundColor: 'white',
                            marginLeft: 10,
                            borderRadius: '10%',
                            width: 35,
                            height: 35,
                        }}
                        onClick={props.onClose}
                        aria-label="close"
                    >
                        <Close />
                    </IconButton>
                )
            }
        </>
    );
};
const useStyles = makeStyles((theme: Theme)=>({
    markera:{
        backgroundColor: theme.palette.secondary.main,
        borderRadius: "50%",
        padding: "25px",
        opacity: 0.6,
        animation: `$pulse 1000ms ${theme.transitions.easing.easeInOut} infinite`
    },
    "@keyframes pulse": {
        "0%": { padding: '22px', opacity: 1 },
        "30%": { padding: '23px', opacity: 0.6 },
        "60%": { padding: '24px', opacity: 0.5 },
        "70%": { padding: '25px', opacity: 0.4 },
        "100%": { padding: '26px', opacity: 0.1 }
    }
}))
export default MapBox

type Props = React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
    coordinate?: LngLatLike
    coordinates?: Array<LngLatLike>
    currentCoordinate?: LngLatLike
    currentZoom?: number
    centerOnLastResult?: boolean
    maxZoom?: number
    isInteractive?: boolean
    isHightLightPosition?: boolean
    circleMarker?: boolean
    onMapInstance?: (instance: mapboxgl.Map) => void
    onRenderPopup?: (coord: LngLatLike) => React.ReactElement | undefined
} & CloseBtnProps

export type TMapBoxLngLatLike = LngLatLike

type CloseBtnProps =
    | { hasCloseButton?: false, onClose?: never }
    | { hasCloseButton?: true, onClose?: () => void }

