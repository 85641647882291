import { useCraftEditorParameters } from "@components/craft-editor/hooks/useCraftEditorParameters"
import { useMemo } from "react"

export const useTextFontFamily = () => {

    const [parameters] = useCraftEditorParameters()
    const options = useMemo(() => {
        return parameters?.properties?.text?.fontFamilyOptions
    }, [parameters])

    return useMemo(() => {
        return options || [
            { value: "Roboto", label: 'Roboto' },
            { value: "Helvetica", label: 'Helvetica' },
            { value: "Arial", label: 'Arial' },
            { value: "sans-serif", label: 'Sans-Serif' }
        ]
    }, [options])
}
