import { useNode } from "@craftjs/core"
import { FormatBold, FormatItalic, FormatUnderlined, Link } from "@mui/icons-material"
import { Box, IconButton } from "@mui/material"
// import { ColorPicker } from "components/form"
import { RichUtils } from "draft-js"
import React, { useState } from "react"
// import { ColorResult } from "react-color"
import { TextLinkDialog, TextLinkDialogValue } from "../dialog/TextLinkDialog"
import { useTextEditorState } from "../elements/hooks/useTextEditorState"
import { useTextCreateEntity } from "./hooks/useTextCreateEntity"
import { useTextGetSelectionData } from "./hooks/useTextGetSelectionData"

export const TextToolsProperty = () => {

    const {
        actions: { setProp },
        fontWeight,
        fontStyle,
        textDecoration
    } = useNode((node) => ({
        fontWeight: node.data.props?.fontWeight,
        fontStyle: node.data.props?.fontStyle,
        textDecoration: node.data.props?.textDecoration,
    }));
    // const [color, setColor] = useState(document.queryCommandValue('foreColor'))
    const [linkValue, setLinkValue] = useState<TextLinkDialogValue | undefined>()
    const { editorState, setEditorState } = useTextEditorState()
    const createEntity = useTextCreateEntity()
    const getSelectionData = useTextGetSelectionData()

    const handleLinkSubmit = (value: TextLinkDialogValue) => {
        if (editorState === undefined) {
            return;
        }
        const _editorState = createEntity(editorState, {
            type: 'LINK',
            data: { url: value.href, target: value.targetBlank ? '_blank' : '', title: value.title }
        })
        setEditorState(_editorState)
        setTimeout(() => setLinkValue(undefined), 250)
    }

    const handleInlineStyleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
        e.preventDefault()
        if (editorState === undefined) {
            return;
        }
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const _editorState = RichUtils.toggleInlineStyle(editorState, value)
            setEditorState(_editorState)
            return
        }
        setProp((props: any) => {
            if (value === 'BOLD') {
                props.fontWeight = fontWeight === 'bold' ? 'normale' : 'bold'
            }

            if (value === 'ITALIC') {
                props.fontStyle = fontStyle === 'italic' ? 'normale' : 'italic'
            }

            if (value === 'UNDERLINE') {
                props.textDecoration = textDecoration === 'underline' ? 'none' : 'underline'
            }

            return props
        })

    }

    const handleLinkClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault()
        if (editorState === undefined) {
            return;
        }

        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            const { url, title, target } = getSelectionData(editorState);
            setLinkValue({ href: url, title, targetBlank: target === '_blank' })
            return;
        }

        setLinkValue({ href: '', title: '' })
    }

    const selection = editorState?.getSelection();

    return (
        <Box display="flex">
            <IconButton
                color={editorState?.getCurrentInlineStyle().has('BOLD') ? 'primary' : 'default'}
                onMouseDown={(e) => handleInlineStyleClick(e, 'BOLD')}>
                <FormatBold />
            </IconButton>

            <IconButton
                color={editorState?.getCurrentInlineStyle().has('ITALIC') ? 'primary' : 'default'}
                onMouseDown={(e) => handleInlineStyleClick(e, 'ITALIC')}>
                <FormatItalic />
            </IconButton>

            <IconButton
                color={editorState?.getCurrentInlineStyle().has('UNDERLINE') ? 'primary' : 'default'}
                onMouseDown={(e) => handleInlineStyleClick(e, 'UNDERLINE')}>
                <FormatUnderlined />
            </IconButton>

            { /** Todo : debug it */ }
            {/* <ColorPicker
                color={color}
                onChange={handleColorChange}
                button={(
                    <IconButton>
                        <FormatColorText />
                    </IconButton>
                )}
            /> */}

            <IconButton
                disabled={selection?.isCollapsed()}
                color={editorState?.getCurrentInlineStyle().has('LINK') ? 'primary' : 'default'}
                onMouseDown={(e) => handleLinkClick(e)}>
                <Link />
            </IconButton>

            {
                linkValue &&
                <TextLinkDialog
                    open={linkValue !== undefined}
                    onClose={() => setLinkValue(undefined)}
                    value={linkValue}
                    onSubmit={handleLinkSubmit}
                />
            }
            
        </Box>
    )
}
