import { TSEO } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useSEOServices } from "./useSEOServices";

export const useSEODelete = () => {
    const service = useSEOServices()
    return useEphattaMutation<TSEO, any, any>(
        ({id}: { id: number}) => service.delete(id),
        {
            log: (data) => `Page SEO ${data?.url} supprimée`
        }
    )
}
