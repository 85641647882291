import { useCallback } from "react"
import { useCreateImage } from "./useCreateImage"

export const useGetCroppedImage = () => {

    const createImage = useCreateImage()

    const getRadianAngle = useCallback((degreeValue: number) => {
        return (degreeValue * Math.PI) / 180
    }, [])

    /**
    * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
    * @param {File} image - Image File url
    * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
    * @param {number} rotation - optional rotation parameter
    */
    const crop = async (imageSrc: any, pixelCrop: any, rotation = 0): Promise<string | null> => {

        const image: HTMLImageElement = await createImage(imageSrc);

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        image.width = image.width / 2;
        image.height = image.height / 2;

        const safeArea = Math.max(image.width, image.height) * 2;

        // set each dimensions to double largest dimension to allow for a safe area for the
        // image to rotate in without being clipped by canvas context
        canvas.width = safeArea;
        canvas.height = safeArea;

        if (!ctx) {
            return null;
        }

        // translate canvas context to a central location on image to allow rotating around the center.
        ctx.translate(safeArea / 2, safeArea / 2);
        ctx.rotate(getRadianAngle(rotation));
        ctx.translate(-safeArea / 2, -safeArea / 2);

        // draw rotated image and store data.
        ctx.drawImage(
            image,
            safeArea / 2 - image.width,
            safeArea / 2 - image.height
        );
        const data = ctx.getImageData(0, 0, safeArea, safeArea);

        // set canvas width to final desired crop size - this will clear existing context
        canvas.width = pixelCrop.width;
        canvas.height = pixelCrop.height;

        // paste generated rotate image with correct offsets for x,y crop values.
        ctx.putImageData(
            data,
            0 - safeArea / 2 + image.width - pixelCrop.x,
            0 - safeArea / 2 + image.height - pixelCrop.y
        );
        return canvas.toDataURL('image/png');
    }

    return crop
}
