import { ContentBlock, ContentState } from "draft-js";
import React, { useCallback } from "react";

export const useDraftFontDecorator = () => {

    const findFontEntities = useCallback((
        block: ContentBlock,
        callback: (start: number, end: number) => void,
        contentState: ContentState
    ) => {
        block.findEntityRanges((character: any) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === "FONT"
            );
        }, callback);
    }, []);

    const getFont = useCallback((props: any) => {
        console.log('getFont', props.contentState.getEntity(props.entityKey))
        const { fontFamily, fontSize, color, backgroundColor, textTransform } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <span 
                data-text-type="font" 
                style={{ 
                    fontFamily, 
                    fontSize, 
                    color, 
                    backgroundColor,
                    textTransform
                }}>
                {props.children}
            </span>
        )
    }, [])

    return {
        findFontEntities,
        getFont
    }
}
