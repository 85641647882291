import { FormatColorText, FormatListBulleted, FormatListNumbered, SmartButton, ViewHeadline } from "@mui/icons-material"
import React, { useMemo } from "react"
import { Button } from "../components/elements/Button"
import { List } from "../components/elements/List"
import { Text } from "../components/elements/Text"
import { useCraftEditorParameters } from "./useCraftEditorParameters"
import { useTextVariants } from "./useTextVariants"
import { ToolboxItemProps } from "./useToolbox"

export const useToolbarTitles = () => {

    const [parameters] = useCraftEditorParameters()
    const textVariants = useTextVariants()

    return useMemo((): Array<ToolboxItemProps> => ([
        {
            label: 'Titre',
            icon: <FormatColorText />,
            element: Text,
            elementProps: { 
                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
                variant: 'h1',
                ...(textVariants[0] || {})
            }
        },
        {
            label: 'Paragraphe',
            icon: <ViewHeadline />,
            element: Text,
            elementProps: { 
                text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod',
                fontWeight: 'normal',
                ...(parameters?.properties?.text?.defaultProps || {}),
                variant: 'body1',
            }
        },
        {
            label: 'Bouton',
            icon: <SmartButton />,
            element: Button,
            elementProps: { 
                label: 'Bouton',
            }
        },
        {
            label: 'Liste numéroté',
            icon: <FormatListNumbered />,
            element: List,
            elementProps: { 
                text: '<li>Lorem ipsum dolor sit amet</li>',
                type: 'ol',
                ...(parameters?.properties?.text?.defaultProps || {}),
                variant: 'body1'
            }
        },
        {
            label: 'Liste à puce',
            icon: <FormatListBulleted />,
            element: List,
            elementProps: { 
                text: '<li>Lorem ipsum dolor sit amet</li>',
                type: 'ul',
                ...(parameters?.properties?.text?.defaultProps || {}),
                variant: 'body1',
            }
        },
    ]), [parameters, textVariants])
}

