import { useMemo } from "react"

export const useContainerSwiperEffect = () => {

    return useMemo(() => {
        return [
            { value: 'slide', label: 'Glisser' },
            { value: 'fade', label: 'Fondu' },
            { value: 'cube', label: 'Cube' },
            { value: 'coverflow', label: 'Couverture' },
            { value: 'flip', label: 'Retourner' },
            { value: 'creative', label: 'Créative' },
            { value: 'cards', label: 'Carte' },
        ]
    }, [])
}
