import React, { CSSProperties, useCallback, useEffect, useRef, useState } from "react";
import { Swiper as SwipperReact, SwiperProps, SwiperSlide } from "swiper/react";
import Swiper, { EffectCards, EffectCoverflow, EffectFade, Grid, Navigation, Pagination } from "swiper";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/grid";
import "swiper/css/pagination";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container";
import { BreakpointProperty } from "../property/BreakpointProperty";
import { ContainerSwiperProperty } from "../property/ContainerSwiperProperty";
import { Add, Delete } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import { useBreakpoint } from "@components/craft-editor/hooks/useBreakpoint";
import { v4 as uuid } from 'uuid';
import { useEditor, useNode } from "@craftjs/core";
import { useBreakpointMatch } from "@components/craft-editor/hooks/useBreakpointMatch";
import { makeStyles } from "@mui/styles";

export const ContainerSwiper = (props: ContainerSwiperProps) => {

    const { id } = useNode();
    const { actions, enabled } = useEditor((state) => ({
        enabled: state.options.enabled
    }));
    const { getValue, setValue, setProp, props: swiperProps } = useBreakpoint()
    const { container } = useBreakpointMatch<ContainerSwiperProps>(props)
    const classes = useStyle()
    const [swiperMaxWidth, setSwiperMaxWidth] = useState(1)
    const containerRef = useRef<HTMLDivElement>()
    const [windowResized, setWindowResized] = useState(false)

    useEffect(() => {
        setProp((props: any) => {
            props.activeIndex = (swiperProps.items?.length - 1) || 0
            return props
        })
    }, [swiperProps.items])

    useEffect(() => {
        if (windowResized) {
            if (containerRef.current) {
                setSwiperMaxWidth(containerRef.current?.offsetWidth)
                setWindowResized(false)
            }
        }
    }, [windowResized])

    const handleImageClick = () => {
        enabled && actions.selectNode(id)
    }

    const handleAddClick = () => {
        setProp((props: any) => {
            props.items.push({
                id: uuid()
            })
            return props
        })
    }

    const handleSlideChange = (swiper: Swiper) => {
        setValue('activeIndex', swiper.activeIndex)
    }

    const handleDeleteClick = (item: ContainerSwiperItemProps) => {
        setProp((props: any) => {
            props.items = props.items?.filter((item1: ContainerSwiperItemProps) => item1.id !== item.id) || []
            return props
        })
    }

    const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
        let rect = entries[0].contentRect;
        let width = rect.width;
        width !== swiperMaxWidth && setSwiperMaxWidth(width)
    }, [])

    const handleRef = (ref: HTMLDivElement) => {
        if (ref) {
            setSwiperMaxWidth(ref.offsetWidth)
            const resizeObserver = new ResizeObserver(handleResize)
            resizeObserver.observe(ref);
            containerRef.current = ref
            window.onresize = () => {
                setWindowResized(true)
                setSwiperMaxWidth(1)
            }
        }
    }

    return (
        <Container container={container} style={{maxWidth: '100%'}}>
            <div ref={handleRef} style={{ width: '100%' }}>
                <SwipperReact
                    {...props.swiper}
                    className={`${props.swiper?.className} ${classes.swiper}`}
                    spaceBetween={parseInt(props.swiper.spaceBetween?.toString() || '0')}
                    modules={[Pagination, Navigation, Grid, EffectFade, EffectCards, EffectCoverflow]}
                    onSlideChange={handleSlideChange}
                    style={{
                        maxWidth: swiperMaxWidth,
                        height: '100%'
                    }}
                >
                    {
                        getValue('items')?.map((item: ContainerSwiperItemProps, index: number) => (
                            <SwiperSlide key={index + 1}>
                                <img width="100%" height="100%" {...item} style={{ objectFit: getValue('objectFit') }} onClick={handleImageClick} />
                                {
                                    enabled &&
                                    <IconButton
                                        onClick={() => handleDeleteClick(item)}
                                        sx={{
                                            position: 'absolute',
                                            top: 15,
                                            right: 15,
                                            backgroundColor: '#0005',
                                            color: '#fff',
                                            zIndex: 999
                                        }}>
                                        <Delete />
                                    </IconButton>
                                }
                            </SwiperSlide>
                        ))
                    }

                    {
                        enabled &&
                        <SwiperSlide>
                            <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
                                <IconButton onClick={handleAddClick} title="Ajouter un élément">
                                    <Add style={{ fontSize: '64px' }} />
                                </IconButton>
                            </Box>
                        </SwiperSlide>
                    }
                </SwipperReact>
            </div>
        </Container>
    )
}

export const ContainerSwiperDefaultProps: Partial<ContainerSwiperProps['swiper']> = {
    slidesPerView: 1,
    grid: { rows: 1 },
    navigation: true,
    allowSlideNext: true,
    allowSlidePrev: true,
    centeredSlides: false,
    loop: false,
    autoplay: false,
    pagination: true
}

const useStyle = makeStyles(() => ({
    swiper: {

    }
}))

ContainerSwiper.craft = {
    displayName: 'Image',
    props: {
        swiper: ContainerSwiperDefaultProps,
        container: {
            ...ContainerDefaultProps,
            elevation: 0
        },
        items: [],
        objectFit: 'cover'
    },
    related: {
        toolbar: ContainerSwiperProperty,
        breakpoint: BreakpointProperty
    },
};

export type ContainerSwiperProps = {
    swiper: Omit<SwiperProps, 'children'> & {

    }
    container: ContainerProps
    activeIndex?: number
    items?: ContainerSwiperItemProps
    objectFit?: CSSProperties['objectFit']
}

export type ContainerSwiperItemProps = {
    id: string
    src?: string
    alt?: string
    title?: string
}

