import React, { useEffect} from "react"
import { Editor, Options as EditorProps , Frame as FrameProps } from "@craftjs/core"
import { Box, ThemeOptions, TypographyProps } from "@mui/material"
import { CraftEditorDesigner } from "./CraftEditorDesigner"
import { useElementResolver } from "./hooks/useElementResolver"
import './css/override.css'
import { useCraftEditorParameters } from "./hooks/useCraftEditorParameters"
import { RenderNode } from "./components/elements/RenderNode"
import { ToolbarProps } from "./components/toolbar/Toolbar"

export const CraftEditor = ({ header, footer, json, data, hideToolbar, onSave, isSaving, enabled, theme, showViewBtn = true , showSaveBtn = true, parameters, ButtonSaveProps, isDesigner, ...props }: CraftEditorProps) => {

    const resolver = useElementResolver()
    const [, setParameters] = useCraftEditorParameters()

    useEffect(() => {
        setParameters(parameters)
    }, [parameters])

    return (
        <Box className="page-container" width="100%">
            <Editor {...props} resolver={resolver} onRender={RenderNode}>
                
                { header }
                
                <CraftEditorDesigner 
                    enabled={enabled} 
                    json={json} 
                    data={data} 
                    hideToolbar={hideToolbar}
                    isDesigner={isDesigner}
                    ToolbarProps={{
                        onSave,
                        showSaveBtn,
                        showViewBtn,
                        isSaving,
                        ButtonSaveProps
                    }}
                    />

                { footer }
            </Editor>
        </Box>
    )
}

export type CraftEditorProps = Partial<EditorProps> & FrameProps & ToolbarProps & {
    theme?: ThemeOptions
    header?: React.ReactNode
    hideToolbar?: boolean
    footer?: React.ReactNode
    showViewBtn?:boolean,
    showSaveBtn?:boolean,
    isDesigner?: boolean
    parameters?: CraftEditorParameters
}

export type CraftEditorParameters = {
    properties?: CraftEditorParametersProperties
}

export type CraftEditorParametersProperties = {
    button?: CraftEditorParametersPropertiesButton
    text?: CraftEditorParametersProperitesText
}

export type CraftEditorParametersPropertiesOption = {
    label?: string
    value?: string
    [key: string]: any
}

export type CraftEditorParametersPropertiesButton = {
    colorOptions?: Array<CraftEditorParametersPropertiesOption>
}

export type CraftEditorParametersProperitesText = {
    variants?: Array<CraftEditorParametersPropertiesTextVariantOption>
    colorOptions?: Array<CraftEditorParametersPropertiesOption>
    fontFamilyOptions?: Array<CraftEditorParametersPropertiesOption>
    fontSizeOptions?: Array<CraftEditorParametersPropertiesOption>
    defaultProps?: TypographyProps
}

export type CraftEditorParametersPropertiesTextVariantOption = CraftEditorParametersPropertiesOption & {
    lineHeight?: string
    textTransform?: string
    fontWeight?: string
    fontSize?: string
    fontFamily?: string
}

