import { useBreakpoint } from "@components/craft-editor/hooks/useBreakpoint"
import { Box, MenuItem, Select, TextField } from "@mui/material"
import React from "react"
import { DropZone } from "components/form";
import { useImageObjectFits } from "./hooks/useImageObjectFits"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { PropertyContainer } from "./PropertyContainer";


export const ContainerSwiperImageProperty = () => {

    const { getValue, setValue, setProp } = useBreakpoint()
    const imageObjectFits = useImageObjectFits()

    const handleDropAccepted = (files: Array<File>) => {
        const [file] = files
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            const binaryStr = reader.result
            handleImgPropertyChange('src', binaryStr as string)
        }
        reader.readAsDataURL(file)
    }

    const handleImgPropertyChange = (key: string, value: string) => {
        const activeIndex = getValue('activeIndex') || 0
        setProp((props: any) => {
            const active = props.items[activeIndex]
            if (active) {
                active[key] = value
            }
            return props
        })
    }

    const getActiveItem = () => {
        const activeIndex = getValue('activeIndex')
        return getValue('items')[activeIndex]
    }

    const getImgProperty = (key: string) => {
        const activeIndex = getValue('activeIndex')
        const items = getValue('items')

        return items[activeIndex]?.[key]
    }

    return (
        <PropertyContainer title="Images du Swiper">
            {
                getActiveItem() &&
                <>
                    <Box p={2}>
                        <DropZone
                            message="Glissez une image ici ou cliquez pour choisir une image"
                            accept={['image/*']}
                            onDropAccepted={handleDropAccepted}
                        />
                    </Box>

                    <PropertyItemTitle variant="body1" fontWeight="normal">Texte alternatif</PropertyItemTitle>
                    <TextField
                        fullWidth
                        value={getImgProperty('alt')}
                        onChange={(e) => handleImgPropertyChange('alt', e.target.value)}
                    />

                    <PropertyItemTitle variant="body1" fontWeight="normal">Titre</PropertyItemTitle>
                    <TextField
                        fullWidth
                        value={getImgProperty('title')}
                        onChange={(e) => handleImgPropertyChange('title', e.target.value)}
                    />
                </>
            }


            <PropertyItemTitle>Adaptation de l'image</PropertyItemTitle>
            <Select
                fullWidth
                value={getValue('objectFit')}
                onChange={(e) => setValue('objectFit', e.target.value)}>
                {
                    imageObjectFits.map((item, index) => (
                        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                    ))
                }
            </Select>
        </PropertyContainer>
    )
}
