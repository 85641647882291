import React, { useState, useEffect } from "react"
import { Box, Button, Slider, Typography } from "@mui/material"
import Cropper, { CropperProps } from "react-easy-crop"
import { useTranslations } from "hooks"
import { RotateLeftRounded, RotateRightRounded } from "@mui/icons-material"
import { useGetCroppedImage } from "./hooks/useGetCroppedImage"
import { Area } from "react-easy-crop/types"
import { TImageEditCoordonates } from "@ephatta/services"
import CropRotateIcon from '@mui/icons-material/CropRotate';

export const PictureCropper = ({ value: _value, defaultValue, onSave, ...props }: PictureCropperProps) => {

    const [
        ZOOM,
        ROTATE,
        SAVE,
        LANDSCAPE,
        PORTRAIT
    ] = useTranslations(i18n)

    const [value, setValue] = useState<string | undefined>(_value || defaultValue)

    useEffect(() => {
        if (_value !== value) {
            setValue(_value)
        }
    }, [_value])

    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>()
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [landscape , seLandscape] = useState(true)

    const cropImage = useGetCroppedImage()

    const handleCropComplete = (croppedArea: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

     const handleZoomChange = async (newZoom: number) => {
        setZoom(newZoom)
    }
    const handleCropSave = async () => {
        try {
            if(props?.backend){ 
                onSave?.(value, {...croppedAreaPixels , rotation} as TImageEditCoordonates)
                return;
            }
            const imageCropped = await cropImage(value, croppedAreaPixels, rotation)
            if (imageCropped) {
                onSave?.(imageCropped)
            }
        } catch (e) {
            console.error(e)
        }
    }

    return (
        <Box display="flex" flexDirection="column">
            <Box position="relative" height={props.cropSize?.height || 320}>
                <Cropper
                    showGrid={false}
                    minZoom={minZoom}
                    {...props}
                    image={value}
                    crop={crop}
                    rotation={rotation}
                    restrictPosition={true}
                    aspect={landscape ? 4/3 : 3/4}
                    zoom={zoom}
                    onCropChange={setCrop}
                    // onRotationChange={setRotation}
                    onCropComplete={handleCropComplete}
                    onZoomChange={handleZoomChange}
                    />
            </Box>

            <Box mt={3}>
                <Typography variant="overline">{ ZOOM }</Typography>

                <Slider
                    value={zoom}
                    min={props.minZoom || minZoom}
                    max={3}
                    step={0.1}
                    onChange={(e, value) => handleZoomChange(value as number)}
                    />
            </Box>

            <Box mt={3}>
                <Typography variant="overline">{ ROTATE }</Typography>

                <Box display="flex" justifyContent="space-between">
                    <Box display="flex">
                        <Button
                            variant="contained"
                            onClick={() => setRotation(rotation - 90)}>
                            <RotateLeftRounded />
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => setRotation(rotation + 90)}>
                            <RotateRightRounded />
                        </Button>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={()=>seLandscape(v => !v)}
                        >
                            <CropRotateIcon sx={{mr: 1}}/> {landscape ? LANDSCAPE : PORTRAIT}
                    </Button>
                </Box>
            </Box>

            <Box mt={3}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleCropSave}>
                    { SAVE }
                </Button>
            </Box>
        </Box>
    )
}

const minZoom = 1

PictureCropper.defaultProps = {
    cropShape: 'round'
}

const i18n = [
    'Generic.PictureCropper.Zoom.label',
    'Generic.PictureCropper.Rotate.label',
    'Generic.PictureCropper.Save.label',
    'Generic.PictureCropper.Landscape.label',
    'Generic.PictureCropper.Portrait.label'
]

export type PictureCropperProps = Partial<CropperProps> & {
    value?: string
    defaultValue?: string
    onSave?: (value?: string, coordinates?: TImageEditCoordonates) => void
    backend?: boolean
}

