import { TAdvertCalendarAvailability } from "@ephatta/services"
import { useCallback } from "react"
import { useInfiniteQuery, useQueryClient } from "react-query"
import { useAdvertAvailabilityService } from "./useAdvertAvailabilityService"

export const useAdvertAvailabilities = (payload: TAdvertCalendarAvailability, refetchOnWindowFocus: boolean = false) => {
    const service = useAdvertAvailabilityService()

    const queryClient = useQueryClient()
    const query = useInfiniteQuery([cacheKey, payload.advertId], (params) => {
        return service.getAll(params?.pageParam || payload)
    },{refetchOnWindowFocus})

    const invalidateQuery = useCallback(() => {
        queryClient.invalidateQueries(cacheKey)
    }, [])

    return {
        ...query,
        invalidateQuery
    }
}

const cacheKey = 'Adverts.Availabilities'
