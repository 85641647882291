import React, { useCallback, useMemo } from "react";
import { Button as MUIButton, ButtonProps as MUIButtonProps } from "@mui/material"
import { Container, ContainerDefaultProps, ContainerProps } from "./Container"
import { ButtonProperty } from "../property/ButtonProperty";
import { Text } from "./Text";
import { useEditor, useNode } from "@craftjs/core";
import { useBreakpointMatch } from "components/craft-editor/hooks/useBreakpointMatch";
import { BreakpointProperty } from "../property/BreakpointProperty";
import { useRedirectHard } from "hooks";
import { useGetSquareProperty } from "components/craft-editor/hooks/useGetSquareProperty";

export const Button = (props: ButtonProps) => {

    const { id } = useNode();
    const { actions, enabled } = useEditor((state) => ({
        enabled: state.options.enabled
    }));

    const {
        setProp
    } = useNode();

    const { label, url, targetBlank, button, container } = useBreakpointMatch<ButtonProps>(props)
    const redirect = useRedirectHard()
    const getSquareProperty = useGetSquareProperty()

    const handleClick = () => {
        if (!enabled) {
            url && redirect(url, targetBlank)
            button.url && redirect(button.url, button.targetBlank)
            return;
        }
        actions.selectNode(id)
    }

    const handleTextChange = useCallback((value?: any) => {
        setProp((props: any) => {
            if (props.button === undefined) {
                props.button = {}
            }

            props.button.label = value
            return props
        })
    }, [setProp])

    const buttonComponent = useMemo(() => (
        <MUIButton
            size="small"
            variant="contained"
            onClick={handleClick}
            color="primary"
            {...{ ...props, ...button }}
            sx={{
                margin: button?.margin ? getSquareProperty(button.margin) : undefined,
                padding: button?.padding ? getSquareProperty(button.padding) : undefined,
                borderRadius: button?.borderRadius ? getSquareProperty(button.borderRadius) : undefined,
                ...button?.sx
            }}>
            <Text
                draggable={false}
                text={button?.label || label || 'Bouton'}
                variant="inherit"
                onChange={(value) => handleTextChange(value)}
            />
        </MUIButton>
    ), [props, button])

    if (!props.draggable) {
        return buttonComponent
    }

    return (
        <Container container={container}>
            { buttonComponent }
        </Container>
    )
}

export const ButtonDefaultProps = {
    label: 'Bouton',
    color: 'primary',
    variant: 'contained',
    url: '',
    targetBlank: false,
}

Button.craft = {
    displayName: 'Button',
    props: {
        container: ContainerDefaultProps,
        button: ButtonDefaultProps,
        draggable: true
    },
    related: {
        toolbar: ButtonProperty,
        breakpoint: BreakpointProperty
    },
};

export type ButtonProps = {
    /**
     * @deprecated use "button.label" instead
     */
    label?: string
    /**
     * @deprecated use "button.url" instead
     */
    url?: string
    /**
     * @deprecated use "button.targetBlank" instead
     */
    targetBlank?: boolean
    button: MUIButtonProps & {
        label?: string
        url?: string
        targetBlank?: boolean
        margin?: string
        padding?: string
    }
    draggable?: boolean
    container?: ContainerProps['container']
}


