import React from 'react'
import {EphattaRouteProps} from "@ephatta/components";

const Dashboard = React.lazy(() => import('./Dashboard'));

const Routes: EphattaRouteProps[] = [
    {
        path: '/dynamic-content',
        element: <Dashboard/>
    },
]

export default Routes
