import { EditorState } from "draft-js";
import { useCallback } from "react"

export const useTextGetAllSelection = () => {

    return useCallback((editorState: EditorState) => {
        let currentContent = editorState.getCurrentContent();

        return editorState.getSelection().merge({
            anchorKey: currentContent.getFirstBlock().getKey(),
            anchorOffset: 0,

            focusOffset: currentContent.getLastBlock().getText().length,
            focusKey: currentContent.getLastBlock().getKey(),
        })
    }, [])
}
