import React from 'react'
import {EphattaRouteProps} from "@ephatta/components";

const Dashboard = React.lazy(() => import('./Dashboard'));
const Details = React.lazy(() => import('./Details'));


const Routes: EphattaRouteProps[] = [
    {
        path: '/facebook-publication',
        element: <Dashboard/>
    },
    {
        path: '/facebook-publication/:id',
        element: <Details />
    },
]

export default Routes
