import { useEditor, Node, FreshNode } from '@craftjs/core';
import { useCallback } from 'react';

export const useDuplicateNode = () => {
    const { actions, query } = useEditor();

    const insertNodeOnParent = useCallback(
        (
            nodeId: string,
            parentId: string,
            indexToInsert: number,
            selectNodeAfterCreated = false,
        ) => {
            const node: Node = query.node(nodeId).get();

            const freshNode: FreshNode = {
                data: {
                    ...node.data,
                    nodes: [],
                },
            };

            const nodeToAdd = query.parseFreshNode(freshNode).toNode();

            actions.add(nodeToAdd, parentId, indexToInsert);

            if (node.data.nodes.length === 0) {
                return;
            }

            node.data.nodes.forEach((childNode: string, index: number) => {
                insertNodeOnParent(childNode, nodeToAdd.id, index);
            });

            if (selectNodeAfterCreated) actions.selectNode(nodeToAdd.id);
        },
        [actions, query],
    );

    const duplicateNode = useCallback((id: string, parent: string) => {
        const parentNode = query.node(parent).get();
        const indexToAdd = parentNode.data.nodes.indexOf(id) + 1;

        insertNodeOnParent(id, parent, indexToAdd, true);
    }, [insertNodeOnParent, query]);

    return duplicateNode
}

