import { useMemo } from "react"

export const useTextCustomStyleMap = () => {

    return useMemo(() => ({
        UPPERCASE: {
            textTransform: 'uppercase'
        },
        LOWERCASE: {
            textTransform: 'lowercase'
        },
        CAPITALIZE: {
            textTransform: 'capitalize'
        },
        fontSize: {}
    }), [])
}
