import { CompositeDecorator } from "draft-js";
import { useMemo } from "react";
import { useDraftFontDecorator } from "./useDraftFontDecorator";
import { useDraftLinkDecorator } from "./useDraftLinkDecorator";

export const useDraftDecorator = () => {

    const { findLinkEntities, getLink } = useDraftLinkDecorator()
    const { findFontEntities, getFont } = useDraftFontDecorator()

    return useMemo(() => {
        return new CompositeDecorator([
            {
                strategy: findLinkEntities,
                component: getLink,
            },
            {
                strategy: findFontEntities,
                component: getFont,
            },
        ]);
    }, [])
}
