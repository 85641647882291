
import { TPaginationPayload } from "@ephatta/services";
import { useEphattaQuery } from "../..";
import { useEventService } from "./useEventService";

export const useEventGetAll = (enabled: boolean = true, payload: TPaginationPayload) => {
    const services = useEventService()
    return useEphattaQuery(
        ["Event.GetAll", payload],
        () => services.getAll(payload),
        { enabled }
    );
};
