
import React from "react";
import { Container, ContainerDefaultProps, ContainerProps } from "./Container"
import Vimeo from '@u-wave/react-vimeo';
import { makeStyles } from "@mui/styles";
import { BreakpointProperty } from "../property/BreakpointProperty";
import { useEditorEnabled } from "@components/craft-editor/hooks/useEditorEnabled";
import { Box } from "@mui/material";
import { VideoVimeoProperty } from "../property/VideoVimeoProperty";
import { useBreakpointMatch } from "@components/craft-editor/hooks/useBreakpointMatch";

export const VideoVimeo = ({ videoId, ...props }: VideoVimeoProps) => {

    const classes = useStyle()
    const enabled = useEditorEnabled()
    const { container } = useBreakpointMatch<Omit<VideoVimeoProps, 'videoId'>>(props)

    return (
        <Container container={container} style={{ display: 'flex', position: 'relative' }}>
            <>
                <Vimeo 
                    key={`craft-editor-vimeo-${videoId}`}
                    video={videoId}
                    responsive
                    transparent={false}
                    className={classes.videoVimeo}
                />
                {
                    enabled &&
                    <Box sx={{ position: 'absolute', inset: "0", zIndex: 99 }} >

                    </Box>
                }
            </>
        </Container>
    )
}

const useStyle = makeStyles(() => ({
    videoVimeo: {
        width: '100%',
        height: 'auto',
        objectFit: "contain",
    }
}))

VideoVimeo.craft = {
    displayName: 'Video Vimeo',
    props: {
        videoId: '',
        container: {
            ...ContainerDefaultProps,
            alignItems: "center",
            justifyContent: "center",
            minHeight: "300px"
        }
    },
    related: {
        toolbar: VideoVimeoProperty,
        breakpoint: BreakpointProperty
    },
};

export type VideoVimeoProps = {
    videoId: string

    container?: ContainerProps['container']
}
