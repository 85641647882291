import { EphattaRouteProps } from '@ephatta/components';
import EventLogRoutes from './event-log/Routes'
import MoUserRoutes from './mo-user/Routes'
import MovementWalletRoutes from './movement-wallet/Routes'
import SecurityAlertRoutes from './security-alert/Routes'
import SEORoutes from './SEO/Routes'
import ExportRoutes from './export/Routes'

const Routes: Array<EphattaRouteProps> = [
    ...EventLogRoutes,
    ...MoUserRoutes,
    ...MovementWalletRoutes,
    ...SecurityAlertRoutes,
    ...SEORoutes,
    ...ExportRoutes
]

export default Routes
