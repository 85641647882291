import { useEphattaMutation } from "@hooks/utils"
import { useCustomerService } from "./useCustomerService"

export const useCustomerDisable = () => {
    const service = useCustomerService()
  return useEphattaMutation( 
    ({email, applicationId}:disableType) => service.disableUser(email, applicationId),
    {
      log: (data, variables) => `Désactiver l'utilisateur <${variables?.email}>`
    }
  )
}
type disableType = {
    email:string, applicationId: number
}