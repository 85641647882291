import React, { useMemo } from "react"
import { ViewCarousel, ViewColumn, ViewDay } from '@mui/icons-material';
import { ToolboxItemProps } from "./useToolbox";
import { Container } from "../components/elements/Container";
import { ContainerSwiper } from "../components/elements/ContainerSwiper";

export const useToolboxContainer = () => {

    return useMemo((): Array<ToolboxItemProps> => ([
        {
            label: 'Conteneur Verticale',
            icon: (<ViewColumn />),
            element: Container,
            elementProps: { 
                container: { 
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    elevation: 1, 
                    minHeight: 60, 
                    padding: '30px', 
                    margin: '5px' 
                } 
            }
        },
        {
            label: 'Conteneur Horizontal',
            icon: (<ViewDay />),
            element: Container,
            elementProps: { 
                container: { 
                    flexDirection: 'column', 
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                    elevation: 1, 
                    minHeight: 60, 
                    padding: '30px', 
                    margin: '5px' 
                } 
            }
        },
        {
            label: 'Conteneur Swiper',
            icon: (<ViewCarousel />),
            element: ContainerSwiper,
            elementProps: { padding: 0, container: { height: 320, elevation: 0 } }
        },
    ]), [])
}
