import { TDynamicContentUpdatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useDynamicContentService } from "./useDynamicContentService";

export const useDynamicContentUpdate = () => {
    const service = useDynamicContentService()
    return useEphattaMutation(
        ({ id, payload }: Payload) => service.update(id, payload),
        {
            log: (data, variables) => {
                return `Contenu dynamique <a href="/dynamic-content">${variables?.payload.value}</a> modifié.`
            }
        }
    )
}

type Payload = {
    id: number
    payload: TDynamicContentUpdatePayload
}