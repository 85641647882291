import { ContentBlock, ContentState } from "draft-js";
import React, { useCallback } from "react";

export const useDraftLinkDecorator = () => {
    const findLinkEntities = useCallback((
        block: ContentBlock,
        callback: (start: number, end: number) => void,
        contentState: ContentState
    ) => {
        block.findEntityRanges((character: any) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === "LINK"
            );
        }, callback);
    }, []);

    const getLink = useCallback((props: any) => {
        const { url, target, title } = props.contentState.getEntity(props.entityKey).getData();
        return (
            <a href={url} target={target} title={title}>
                {props.children}
            </a>
        )
    }, [])

    return {
        findLinkEntities,
        getLink
    }
}
