import React from "react"
import { Checkbox, FormControlLabel, FormGroup, MenuItem, TextField } from "@mui/material"
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint"
import { PropertyContainer } from "./PropertyContainer"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { useContainerSwiperChangeHandler } from "./hooks/useContainerSwiperChangeHandler"
import { useContainerSwiperDirection } from "./hooks/useContainerSwiperDirection"
import { useContainerSwiperPaginationType } from "./hooks/useContainerSwiperPaginationType"
import { ContainerProperty } from "./ContainerProperty"
import { ContainerSwiperImageProperty } from "./ContainerSwiperImageProperty"
import { useContainerSwiperEffect } from "./hooks/useContainerSwiperEffect"

export const ContainerSwiperProperty = () => {

    const { getValue } = useBreakpoint()
    const directions = useContainerSwiperDirection()
    const effects = useContainerSwiperEffect()
    const paginationTypes = useContainerSwiperPaginationType()
    const handleContainerSwiperChange = useContainerSwiperChangeHandler()

    return (
        <>
            <ContainerProperty
                hideAlignment
                hideBackground
                hideDirection
            />
            <PropertyContainer title="Swiper">
                <PropertyItemTitle>Slide par vue</PropertyItemTitle>
                <TextField
                    type="number"
                    fullWidth
                    value={getValue('swiper.slidesPerView')}
                    onChange={(e) => handleContainerSwiperChange('slidesPerView', e.target.value)}
                />

                <PropertyItemTitle>Lignes</PropertyItemTitle>
                <TextField
                    type="number"
                    fullWidth
                    value={getValue('swiper.grid.rows')}
                    onChange={(e) => handleContainerSwiperChange('grid.rows', e.target.value)}
                />

                <PropertyItemTitle>Espacement</PropertyItemTitle>
                <TextField
                    type="number"
                    fullWidth
                    value={getValue('swiper.spaceBetween')}
                    onChange={(e) => handleContainerSwiperChange('spaceBetween', e.target.value)}
                />

                <PropertyItemTitle>Direction</PropertyItemTitle>
                <TextField
                    select
                    fullWidth
                    value={getValue('swipper.direction')}
                    onChange={(e) => handleContainerSwiperChange('direction', e.target.value)}>
                    {
                        directions.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </TextField>

                <PropertyItemTitle>Effet</PropertyItemTitle>
                <TextField
                    select
                    fullWidth
                    value={getValue('swipper.effect')}
                    onChange={(e) => handleContainerSwiperChange('effect', e.target.value)}>
                    {
                        effects.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </TextField>

                <PropertyItemTitle>Pagination</PropertyItemTitle>
                <FormControlLabel
                    control={<Checkbox checked={getValue('swiper.pagination')} />}
                    label="Pagination activé"
                    onChange={(e, checked) => handleContainerSwiperChange('pagination', checked)}
                />
                {
                    getValue('swiper.pagination') &&
                    <>
                        <PropertyItemTitle>Type de pagination</PropertyItemTitle>
                        <TextField
                            select
                            fullWidth
                            value={getValue('swiper.pagination.type')}
                            onChange={(e) => handleContainerSwiperChange('pagination.type', e.target.value)}>
                            {
                                paginationTypes.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                                ))
                            }
                        </TextField>
                    </>
                }


                <PropertyItemTitle>Options</PropertyItemTitle>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={getValue('swiper.navigation')} />}
                        label="Navigation"
                        onChange={(e, checked) => handleContainerSwiperChange('navigation', checked)}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={getValue('swiper.allowSlideNext')} />}
                        label="Activer Glisser Suivant"
                        onChange={(e, checked) => handleContainerSwiperChange('allowSlideNext', checked)}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={getValue('swiper.allowSlidePrev')} />}
                        label="Activer Glisser Précédent"
                        onChange={(e, checked) => handleContainerSwiperChange('allowSlidePrev', checked)}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={getValue('swiper.centeredSlides')} />}
                        label="Centré"
                        onChange={(e, checked) => handleContainerSwiperChange('centeredSlides', checked)}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={getValue('swiper.loop')} />}
                        label="Boucler"
                        onChange={(e, checked) => handleContainerSwiperChange('loop', checked)}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={getValue('swiper.autoplay')} />}
                        label="Animation automatique"
                        onChange={(e, checked) => handleContainerSwiperChange('autoplay', checked)}
                    />
                </FormGroup>
            </PropertyContainer>
            <ContainerSwiperImageProperty />
        </>
    )
}
