import { Box, Drawer, Grid, Hidden, IconButton } from "@mui/material"
import { makeStyles } from "@mui/styles";
import { Suspense, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import { Outlet } from "react-router";
import { GenericSuspenseWrapper, MainLoader } from "@ephatta/components";
import { Header } from "./component/Header";
import { Toolbar } from "./component/Toolbar";

export const MainLayout = () => {

    const classes = useStyles()
    const [mobileOpen, setMobileOpen] = useState(false);

    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Toolbar onDrawerToggle={handleDrawerToggle} />
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={12} sm={12} md={3} lg={2}>
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}>
                            <IconButton
                                onClick={handleDrawerToggle}
                                className={classes.closeMenuButton}
                                size="large">
                                <CloseIcon />
                            </IconButton>
                            <Header />
                        </Drawer>
                        <Box  sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                            <Header />
                        </Box>
                    </Grid>
                    
                    <Grid item xs={12} sm={12} md={9} lg={10} p={5} className={classes.screen}>
                        <GenericSuspenseWrapper fallback={<MainLoader src="/assets/img/logo.png" />}>
                            <Outlet />
                        </GenericSuspenseWrapper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    screen: {
        backgroundColor: theme.palette.secondary.light,
        '&.MuiGrid-root': {
            marginTop: 90,
        }
    },
    applicationFullScreen: {
        width: '100%',
        position: 'relative',
        minHeight: `${window.innerHeight}px`,
        background: '#f5f6fa',
        '& > div': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate3d(-50%, -50%, 0)'
        }
    },
    //#region main style
    application: {
        backgroundColor: '#f5f6fa',
        '& > div:nth-child(2)': {
            minHeight: `${window.innerHeight * 0.9}px`,
            width: '-webkit-fill-available'
        },
        '& > div:last-child': {
            minHeight: `${window.innerHeight * 0.1}px`,
            position: 'relative',
            backgroundColor: 'gray',
        },
    },
    closeMenuButton: {
        position: 'absolute',
        right: 0,
        margin: '20px 5px',
        padding: '10px',
        // backgroundColor: `${secondColor} !important`, CLEAN_ME
        zIndex: 1
    },
}))
