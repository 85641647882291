import { MainLoader, useEventInsert, useTranslations } from "@ephatta/components"
import { setAccessToken, setLocalStorageEstablishment } from "@ephatta/services"
import { useEffect } from "react"

const Logout = () => {

    const mutation = useEventInsert()

    const [DECONNECT] = useTranslations(i18n)

    useEffect(() => {

        mutation.mutate({
            message: `Deconnexion`
        }, {
            onSuccess: () => {
                setLocalStorageEstablishment('')
                setAccessToken('')
                window.location.href = '/login'
            }
        })
    }, [])

    return (
        <MainLoader />
    )
}

const i18n = [
    "MiddleOffice.Account.Deconnection"
]

export default Logout