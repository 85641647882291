import {FormEvent, useEffect} from "react";
import {Grid, TextField, Typography} from "@mui/material";
import {ButtonSubmit, useFormData, useSEOInsert, useSEOUpdate, useTranslations} from "@ephatta/components";
import { TSEO } from "@ephatta/services";

const CreateOrEdit = (props: TSEO & { onSubmited?: () => void }) => {
    const insertMutation = useSEOInsert()
    const updateMutation = useSEOUpdate(props.id || 0)
    const {formData, getTextFieldProps, isValid} = useFormData({
        formData: props,
        required: ['url', 'metaTitle']
    })

    useEffect(() => {
        (insertMutation.isSuccess || updateMutation.isSuccess) && props.onSubmited?.()
    }, [insertMutation, updateMutation])

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (isValid() && formData.id) {
            updateMutation.mutate(formData)
        } else {
            insertMutation.mutate(formData)
        }
    }

    const [
        MODAL_TITLE,
        MODAL_ACTION_SAVE,
        FORM_META_TITLE,
        FORM_META_DESCRIPTION,
        FORM_META_KEYWORDS,
    ] = useTranslations([
            "MiddleOffice.SEO.Dashboard.Modal.CreateMeta.Title",
            "MiddleOffice.SEO.Dashboard.Modal.CreateMeta.ButtonSave",
            "MiddleOffice.SEO.Dashboard.Modal.CreateMeta.Form.Meta.Title",
            "MiddleOffice.SEO.Dashboard.Modal.CreateMeta.Form.Meta.Description",
            "MiddleOffice.SEO.Dashboard.Modal.CreateMeta.Form.Meta.Keyword"
        ]
    )

    return (
        <form onSubmit={handleSubmit}>
            <Typography variant="h5" className="mb-30">{ MODAL_TITLE }</Typography>
            <Grid container>

                <TextField
                    label="URL"
                    placeholder=""
                    {...getTextFieldProps('url')}
                    autoFocus
                    style={{width: '100%', marginBottom: '15px'}}/>

                <TextField
                    label={FORM_META_TITLE}
                    placeholder="Titre"
                    {...getTextFieldProps('metaTitle')}
                    style={{width: '100%', marginBottom: '15px'}}/>

                <TextField
                    label={FORM_META_DESCRIPTION}
                    placeholder="Description"
                    multiline
                    rows={5}
                    {...getTextFieldProps('metaDescription')}
                    style={{width: '100%', marginBottom: '15px'}}/>

                <TextField
                    label={FORM_META_KEYWORDS}
                    placeholder="Mot clé"
                    multiline
                    rows={5}
                    {...getTextFieldProps('metaKeywords')}
                    style={{width: '100%', marginBottom: '15px'}}/>

                <Grid xs={12}>
                    <ButtonSubmit
                        color="primary"
                        variant="contained"
                        className="rounded mt-30 float-right"
                        type="submit"
                        isSubmit={insertMutation.isLoading || updateMutation.isLoading}>
                        { MODAL_ACTION_SAVE }
                    </ButtonSubmit>
                </Grid>
            </Grid>
        </form>
    )
}

export default CreateOrEdit;