import { TAdvertComment } from "@ephatta/services"
import { useEphattaMutation } from "@hooks/utils"
import { useAdvertCommentService } from "./useAdvertCommentService"

export const useAdvertCommentUpdate = () => {
    const services = useAdvertCommentService()
  return useEphattaMutation(
    (payload: TAdvertComment) => services.update(payload),
    {
        successMessage:"Statut de la commentaire modifié avec succès",
        log(data?, variables?) {
            return `Statut du commentaire <a href="/comments/${variables?.id}">${variables?.id}</a> modifié en ${variables?.state}`
        }
    }
  )

}
