
import { GridSortDirection, GridSortModel } from "@mui/x-data-grid";
import { TUrlFilter,  useUrlParamsState } from "hooks";
import { useCallback, useMemo } from "react"

export const useDataGridFilter = (defaultValue?: Record<string, any>) => {

    const { urlParams, setUrlParams } = useUrlParamsState<TSortFilter>(defaultValue)

    const handleSortModelChange = useCallback((newModel: GridSortModel) => {
        const [model] = newModel
        if (model) {
            setUrlParams((v)=>({...v, sort: model.sort, orderBy: model.field }))
        } else {
            setUrlParams((v)=>({...v, sort: undefined, orderBy: undefined}))

        }
    }, [setUrlParams]);

    const handlePageSizeChange = (value: number) => {
        setUrlParams('pageSize', value)
    };

    const handlePageChange = (value: number) => {
        setUrlParams('page', value)
    };

    const sortModel = useMemo((): GridSortModel | undefined => {
        if (!urlParams.sort || ! urlParams.orderBy)  return undefined
        return [
            { sort: urlParams.sort, field: urlParams.orderBy }
        ]
    }, [urlParams])

    const rowPerPage = useMemo(() => ([5, 10, 20, 50, 100]), [])

    return {
        formData: {
            ...urlParams,
            pageSize: parseInt(urlParams.pageSize?.toString() || '20'),
            page: parseInt(urlParams.page?.toString() || '0'),
        },
        sortModel,
        rowPerPage,
        handleSortModelChange,
        handlePageSizeChange,
        handlePageChange
    }
}

export type TSortFilter = TUrlFilter & {
    sort?: GridSortDirection | null
    orderBy?: string | null
    pageSize?: number | null
    page?: number
}
