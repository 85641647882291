import {useEffect, useMemo, useState} from "react";
import {GridColDef} from "@mui/x-data-grid";
import Link from "@mui/material/Link/Link";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import {FilterDataGrid, useSEODelete, useSEOGetAll, useTranslations} from "@ephatta/components";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import CreateOrEdit from "./widgets/CreateOrEdit";
import {ThemeProvider} from "@mui/styles";
import {frFR} from '@mui/material/locale'
import {DialogActions, DialogTitle, Typography} from "@mui/material";
import { TSEO } from "@ephatta/services";

const Dashboard = () => {

    // Route: /seo
    const pathname = useMemo(() => '/seo', [])

    // Initialize state data for SEO
    const [data, setData] = useState<TSEO>();

    // React query to get all SEO data
    const {data: rows, isLoading, invalidateQuery} = useSEOGetAll();

    // Delete data
    const deleteSEODataMutation = useSEODelete();

    // Open the modal state
    const [openConfirmDeleteDialog, setOpenConfirmDeleteDialog] = useState<boolean>(false)

    // Confirm delete state
    const [metaWillDelete, setMetaWillDelete] = useState<{ id: number, url: any }>()

    useEffect(() => {
        if (deleteSEODataMutation.isSuccess) {
            invalidateQuery()
            deleteSEODataMutation.reset()
        }
    }, [deleteSEODataMutation])

    const handleClose = () => {
        setData(undefined)
    }

    const handleDeletion = (meta: { id: number, url: any }) => {
        setOpenConfirmDeleteDialog(true);
        setMetaWillDelete(meta);
    }

    const handleConfirmDeletion = () => {
        setOpenConfirmDeleteDialog(false);
        deleteSEODataMutation.mutate({id: metaWillDelete?.id});
    }

    // Define the translation
    const [
        CREATE_META,
        EDIT_META,
        DELETE_META,
        DELETE_META_CONFIRMATION_MODAL_TITLE,
        DELETE_META_CONFIRMATION_MODAL_CONTENT,
        DELETE_META_CONFIRMATION_MODAL_BUTTON_YES,
        DELETE_META_CONFIRMATION_MODAL_BUTTON_NO,
    ] = useTranslations([
        "MiddleOffice.SEO.Dashboard.CreateMeta",
        "MiddleOffice.SEO.Dashboard.EditMeta",
        "MiddleOffice.SEO.Dashboard.DeleteMeta",
        "MiddleOffice.SEO.Dashboard.Modal.Delete.Confirmation.Title",
        "MiddleOffice.SEO.Dashboard.Modal.Delete.Confirmation.Body",
        "MiddleOffice.SEO.Dashboard.Modal.Delete.Confirmation.Yes",
        "MiddleOffice.SEO.Dashboard.Modal.Delete.Confirmation.No"
    ])

    // Define columns for data grid
    const columns: GridColDef[] = useMemo(() => [
                {
                    field: 'url',
                    headerName: 'URL',
                    flex: 1,
                    renderCell: (param) => (
                        <Link onClick={() => setData(param.row as TSEO)}>{param.row?.url}</Link>
                    )
                },
                {
                    field: 'id',
                    headerName: 'Action',
                    flex: 1,
                    renderCell: (param) => (
                        <>
                            <Button
                                color="secondary"
                                variant="contained"
                                className="rounded"
                                type="button"
                                onClick={() => setData(param.row as TSEO)}>
                                {EDIT_META}
                            </Button>
                            <span>&nbsp;</span>
                            <Button
                                color="error"
                                variant="contained"
                                className="rounded"
                                type="button"
                                onClick={() => handleDeletion({id: param.row.id, url: param.row.url})}>
                                {DELETE_META}
                            </Button>
                        </>
                    )
                }
            ],
            [deleteSEODataMutation]
        );

    return (
        <>
            <Typography variant="h4" fontWeight="bold" color="secondary" mb={5}>
                SEO
            </Typography>
            <Grid container>
                <Grid lg={8} sm={12} xs={12}>
                    <Button
                        color="primary"
                        variant="contained"
                        className="rounded mt-30 float-right"
                        onClick={() => setData({
                            id: null,
                            metaDescription: '',
                            metaKeywords: '',
                            metaTitle: '',
                            url: ''
                        })}>
                        { CREATE_META }
                    </Button>
                </Grid>

                <Grid lg={8} sm={12} xs={12}>
                    <Paper className="mt-30 p-20" elevation={3} square>
                        <ThemeProvider theme={frFR}>
                            <FilterDataGrid
                                pathname={pathname}
                                loading={isLoading || deleteSEODataMutation.isLoading}
                                rows={rows || []}
                                columns={columns}
                            />
                        </ThemeProvider>
                    </Paper>
                </Grid>
            </Grid>


            <Dialog
                open={data !== undefined}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogContent>
                    {
                        data && <CreateOrEdit {...data} onSubmited={() => {
                            invalidateQuery()
                            setData(undefined)
                        }}/>
                    }
                </DialogContent>
            </Dialog>

            <Dialog
                open={openConfirmDeleteDialog}
            >
                <DialogTitle style={{cursor: 'move'}} id="draggable-dialog-title">
                    { DELETE_META_CONFIRMATION_MODAL_TITLE }
                </DialogTitle>
                <DialogContent>
                    { DELETE_META_CONFIRMATION_MODAL_CONTENT }  <b>{metaWillDelete?.url}</b> ?
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleConfirmDeletion()}>
                        { DELETE_META_CONFIRMATION_MODAL_BUTTON_YES }
                    </Button>
                    <Button autoFocus onClick={() => setOpenConfirmDeleteDialog(false)}>
                        { DELETE_META_CONFIRMATION_MODAL_BUTTON_NO }
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default Dashboard