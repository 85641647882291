import React from "react"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import { useDataGridFilter } from "./hooks/useDataGridFilter"

export const FilterDataGrid = ({ defaultFilter, ...props }: Props) => {

    const { 
        handleSortModelChange, 
        sortModel, 
        rowPerPage, 
        handlePageSizeChange,
        handlePageChange, 
        formData 
    } = useDataGridFilter(defaultFilter)
    

    return (
        <DataGrid
            autoHeight
            sortingMode="client"
            paginationMode="client"
            filterMode="client"
            rowsPerPageOptions={rowPerPage}
            page={formData.page}
            pageSize={formData.pageSize || 20}
            onPageChange={handlePageChange}
            {...props}
            sortModel={sortModel}
            onPageSizeChange={handlePageSizeChange}
            onSortModelChange={handleSortModelChange}
        />
    )
}

type Props = DataGridProps & {
    /**
     * @deprecated this prop is not used
     */
    pathname?: string
    defaultFilter?: Record<string, any>
}
