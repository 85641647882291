import { TDynamicContent } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useDynamicContentService } from "./useDynamicContentService";

export const useDynamicContentDelete = () => {
    const service = useDynamicContentService()
    return useEphattaMutation(
        (payload: TDynamicContent) => service.delete(payload.id),
        {
            log: (data, variables) => {
                return `Contenu dynamique <a href="/dynamic-content">${variables?.value}</a> supprimé.`
            }
        }
    )
}
