import { TextEditorState } from "@components/craft-editor/state/TextEditorState";
import { useNode } from "@craftjs/core";
import { useRecoilState } from "recoil";

export const useTextEditorState = () => {

    const { id } = useNode();

    const [editorState, setEditorState] = useRecoilState(TextEditorState(id))

    return {
        editorState: editorState,
        setEditorState
    }
}
