import React, { useEffect } from "react"
import { GenericDialog, GenericDialogType } from "components/dialog"
import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from "@mui/material"
import { useTextLinkFormData } from "./hooks/useTextLinkFormData"
import _ from "lodash"

export const TextLinkDialog = ({ onSubmit, value, ...props }: TextLinkDialogProps) => {

    const { formData, getTextFieldProps, handleInputChange, isValid, setFormData } = useTextLinkFormData(value)

    useEffect(() => {
        if (!_.isEqual(value, formData)) {
            setFormData(value)
        }
    }, [value])

    const handleSubmit = () => {
        if (isValid()) {
            onSubmit?.(formData)
        }
    }

    return (
        <GenericDialog
            {...props}
            title="Insérer un lien"
            onConfirm={() => handleSubmit()}>
                <Box display="flex" flexDirection="column" gap={3} mt={5}>
                    <TextField
                        label="Lien"
                        fullWidth
                        {...getTextFieldProps('href')}
                        />

                    <TextField
                        label="Texte"
                        fullWidth
                        {...getTextFieldProps('title')}
                        />

                    <FormGroup>
                        <FormControlLabel 
                            control={
                                <Checkbox 
                                    checked={formData.targetBlank}
                                    onChange={(e, checked) => handleInputChange('targetBlank', checked)}
                                    />
                            } 
                            label="Ouvrir un nouvel onglet lors du clic"
                            />
                    </FormGroup>
                </Box>
        </GenericDialog>
    )
}

export type TextLinkDialogProps = Omit<GenericDialogType, 'onSubmit'> & {
    value: TextLinkDialogValue
    onSubmit?: (value: TextLinkDialogValue) => void
}

export type TextLinkDialogValue = {
    href: string
    title: string
    targetBlank?: boolean
}
