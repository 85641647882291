import { EphattaRouteProps } from "@ephatta/components"
import React from "react"
import Logout from './Logout'

const Routes: EphattaRouteProps[] = [
    {
        path: '/logout',
        element: <Logout />,
    },
]

export default Routes
