import React from 'react'
import {EphattaRouteProps} from "@ephatta/components";

const Dashboard = React.lazy(() => import('./Dashboard'));
const BookingDetails = React.lazy(() => import('./BookingDetails'))

const Routes: EphattaRouteProps[] = [
    {
        path: '/booking',
        element: <Dashboard/>
    },
    {
        path: '/booking/:id',
        element: <BookingDetails />
    },
]

export default Routes
