import { useCraftEditorParameters } from "@components/craft-editor/hooks/useCraftEditorParameters"
import { useTheme } from "@mui/material"
import { useMemo } from "react"

export const useTextColors = () => {

    
    const [parameters] = useCraftEditorParameters()
    const theme = useTheme()

    const options = useMemo(() => {
        return parameters?.properties?.text?.colorOptions
    }, [parameters])

    return useMemo(() => {
        return options || [
            { value: theme.palette.primary.main, label: 'Primaire' },
            { value: theme.palette.secondary.main, label: 'Secondaire' },
            { value: theme.palette.primary.light, label: 'Blanche' },
        ]
    }, [options])
}

export const COLORS = {
    aztecGold: '#CA9B52',
    chinaRose: '#AD5068',
    white: '#FFFFFF',
    black: '#111111',
    cream: '#FAF7F4',
    gray: '#9098B1',
    red: '#971033',
    grayBlue: '#EBF0FF',
    grayBlueLight: '#F2F2F2',
    redMain: '#9E0303',
    philippineGray: '#898A8D'
};
