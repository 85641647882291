import { EphattaRouteProps } from '@ephatta/components';
import React from 'react'
import { Container } from './Container';

const Routes: EphattaRouteProps[] = [
    {
        path: '/export',
        element: <Container />
    },
]

export default Routes
