import { TAdvertSubscriptionAssignationCreatePayload } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import _ from "lodash";
import { useAdvertSubscriptionAssignmentService } from "./useAdvertSubscriptionAssignmentService";

export const useAdvertSubscriptionAssignationCreate = () => {
    const service = useAdvertSubscriptionAssignmentService()
    return useEphattaMutation(
        (payload: TAdvertSubscriptionAssignationCreatePayload) => service.create(_.omit(payload, "label")),
        {
            log: (data, variables) => {
                return `Abonnement ${variables?.label} ajouté pour l'annonce <a href="/advert/${variables?.advertId}">${variables?.advertId}</a>`
            }
        }
    )
}