import React from "react";
import { Element, useEditor } from "@craftjs/core";
import { Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material"
import { useToolbox } from "../../hooks/useToolbox";

export const Toolbox = (props: Props) => {

    const tools = useToolbox()
    const {connectors} = useEditor();

    return (
        <List>
            {
                tools.map((item, index) => {

                    if (item.isDivider) {
                        return (
                            <Divider />
                        )
                    }

                    return (
                        <ListItem disablePadding key={index}>
                            <ListItemButton
                                title={item.label}
                                ref={(ref) => connectors
                                    .create(
                                        ref!, 
                                        <Element canvas is={item.element as any} {...item.elementProps} />
                                    )
                                }>
                                <ListItemIcon>
                                    { item.icon }
                                </ListItemIcon>
                                {
                                    props.hideText !== true &&
                                    <ListItemText primary={item.label} />
                                }
                            </ListItemButton>
                        </ListItem>
                    )
                })
            }
        </List>
    )
}

type Props = {
    hideText?: boolean
}
