import { TCustomer } from "@ephatta/services"
import { useEphattaMutation } from "@hooks/utils"
import { useCustomerMiddleOfficeServices } from "./useCustomerMiddleOfficeServices"

export const useCustomerMiddleOficeDelete = () => {
  const services = useCustomerMiddleOfficeServices()
  return useEphattaMutation(
    (payload: TCustomer) => services.delete(payload.userId), {
    log: (data, variables) => {
      return `L'utilisateur MiddleOffice <a href="/mo-user">${variables?.firstname} ${variables?.lastname} <${variables?.email}></a> a été supprimé.`
    }
  }
  )
}
