import { TAdvertEnvironment } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { useAdvertEnvironmentService } from "./useAdvertEnvironmentService";

export const useAdvertEnvironmentDelete = () => {
    const service = useAdvertEnvironmentService()
    const { i18n } = useTranslation()
    return useEphattaMutation(
        (payload: TAdvertEnvironment) => service.delete(payload.id),
        {
            log: (data, variables) => {
                const value = variables?.values.find((item) => item.culture === i18n.language)
                return `Environements <a href="/environments">${value?.value}</a> supprimé.`
            }
        }
    )
}
