import { useEphattaQuery } from "@hooks/utils";
import { useAdminTransactionsServices } from "./useAdminTransactionsServices";

export const useAdminTransaction = (id: number, suspense?:boolean) => {
    const services = useAdminTransactionsServices();
  return useEphattaQuery( 
    ["Get.AdminTransaction.ById", id],
    () => services.getById(id),
    {
        enabled: !!id,
        suspense
    }
  )
}
