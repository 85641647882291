import { useMemo } from "react"
import { useCraftEditorParameters } from "./useCraftEditorParameters"

export const useTextVariants = () => {

    const [parameters] = useCraftEditorParameters()

    const options = useMemo(() => {
        return parameters?.properties?.text?.variants
    }, [parameters])

    return useMemo(() => {
        return options || [
            {
                label: 'Titre 1',
                value: 'h1'
            },
            {
                label: 'Titre 2',
                value: 'h2'
            },
            {
                label: 'Titre 3',
                value: 'h3'
            },
            {
                label: 'Titre 4',
                value: 'h4'
            },
            {
                label: 'Titre 5',
                value: 'h5'
            },
            {
                label: 'Titre 6',
                value: 'h6'
            },
            {
                label: 'Paragraphe',
                value: 'body1'
            },
        ]
    }, [options])
}
