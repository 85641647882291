import React from "react"
import { Box, IconButton, MenuItem, Select } from "@mui/material"
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { ColorPicker } from "components/form"
import { useTextColors } from "./hooks/useTextColors"
import { ColorLens } from "@mui/icons-material"
import { useTextEditorFontChange } from "../elements/hooks/useTextEditorFontChange"
import { useTextEditorSelectedFontData } from "../elements/hooks/useTextEditorSelectedFontData"

export const TextColorProperty = () => {

    const { getValue } = useBreakpoint()
    const colors = useTextColors()
    const handleFontChange = useTextEditorFontChange()
    const fontData = useTextEditorSelectedFontData()
    
    return (
        <>
            <PropertyItemTitle>Couleur</PropertyItemTitle>
            <Box display="flex" gap="10px">
                <Select
                    fullWidth
                    value={fontData?.color ? fontData?.color : getValue('color')}
                    onChange={(e) => handleFontChange('color', e.target.value)}>
                    {
                        colors.map((item, index) => (
                            <MenuItem key={index} value={item.value} sx={{ display: 'flex' }}>
                                <Box 
                                    component="span"
                                    sx={{ 
                                        width: '18px', 
                                        height: '18px', 
                                        borderRadius: '18px', 
                                        backgroundColor: item.value,
                                        marginRight: '15px'
                                    }}></Box> {item.label}
                            </MenuItem>
                        ))
                    }
                </Select>
                <Box>
                    <ColorPicker
                        color={fontData?.color ? fontData?.color : getValue('color')}
                        onChange={(color) => handleFontChange('color', color.hex)}
                        button={(
                            <IconButton>
                                <ColorLens />
                            </IconButton>
                        )}
                    />
                </Box>
            </Box>
        </>
    )
}
