import { useBreakpoint } from "@components/craft-editor/hooks/useBreakpoint"
import { EditorState, Modifier, RichUtils, SelectionState } from "draft-js"
import { useCallback } from "react"
import { useTextGetSelectionData } from "../../property/hooks/useTextGetSelectionData"
import { useTextToogleInlineStyle } from "../../property/hooks/useTextToogleInlineStyle"
import { useTextEditorState } from "./useTextEditorState"
import { useTextGetAllSelection } from "./useTextGetAllSelection"
// @ts-ignore
import { getAllBlocks } from 'draftjs-utils';

export const useTextEditorFontChange = () => {

    const { setValue } = useBreakpoint()
    const { editorState, setEditorState } = useTextEditorState()
    const getSelectionData = useTextGetSelectionData()
    const toggleInlineStyle = useTextToogleInlineStyle()
    const getSelectionAll = useTextGetAllSelection()

    const cleaTextTransform = useCallback((editorState: EditorState, selection: SelectionState) => {
        const nextContentState = ['UPPERCASE', 'LOWERCASE', 'CAPITALIZE'].reduce(
            (contentState, s) => Modifier.removeInlineStyle(contentState, selection, s),
            editorState.getCurrentContent()
        );
        return EditorState.push(
            editorState,
            nextContentState,
            'change-inline-style'
        );
    }, [])

    return useCallback((key: string, value: any) => {

        if (editorState === undefined) {
            return;
        }

        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {



            if (key === 'textTransform') {
                const currentStyle = editorState.getCurrentInlineStyle();
                if (!currentStyle.has(key)) {
                    // remove other styles
                    let nextEditorState = cleaTextTransform(editorState, selection)
                    if (value !== 'none') {
                        nextEditorState = RichUtils.toggleInlineStyle(nextEditorState, value.toUpperCase());
                    }
                    setEditorState(nextEditorState)
                    return;
                }
            }
            
            const _editorState = toggleInlineStyle(editorState, key, value)
            setEditorState(_editorState)
            return;
        }

        if (key === 'textTransform') {
            const selectionAll = getSelectionAll(editorState)
            let nextEditorState = cleaTextTransform(editorState, selectionAll)
            setEditorState(nextEditorState)
        }

        setValue(key, value, false)

    }, [getSelectionData, setValue, setEditorState, editorState])
}
