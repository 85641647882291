import React from "react";
import { Box, MenuItem, Select, TextField } from "@mui/material"
import { PropertyItemTitle } from "./PropertyItemTitle"
import { DropZone } from "components/form";
import { ContainerProperty } from "./ContainerProperty";
import { PropertyContainer } from "./PropertyContainer";
import { useBreakpoint } from "components/craft-editor/hooks/useBreakpoint";
import { useImageObjectFits } from "./hooks/useImageObjectFits";

export const ImageProperty = () => {

    const { getValue, setValue, setProp } = useBreakpoint()
    const imageObjectFits = useImageObjectFits()

    const handleDropAccepted = (files: Array<File>) => {
        const [file] = files
        const reader = new FileReader()

        reader.onabort = () => console.log('file reading was aborted')
        reader.onerror = () => console.log('file reading has failed')
        reader.onload = () => {
            const binaryStr = reader.result
            setProp((props: any) => props.src = binaryStr)
        }
        reader.readAsDataURL(file)
    }

    return (
        <>

            <ContainerProperty
                hideDirection
                hideAlignment
                hideBackground
            />

            <PropertyContainer title="Télécharger une image">
                <Box p={2}>
                    <DropZone
                        message="Glissez une image ici ou cliquez pour choisir une image"
                        accept={['image/*']}
                        onDropAccepted={handleDropAccepted}
                    />
                </Box>

                <PropertyItemTitle variant="body1" fontWeight="normal">Texte alternatif</PropertyItemTitle>
                <TextField
                    fullWidth
                    value={getValue('alt')}
                    onChange={(e) => setValue('alt', e.target.value)}
                />

                <PropertyItemTitle variant="body1" fontWeight="normal">Titre</PropertyItemTitle>
                <TextField
                    fullWidth
                    value={getValue('title')}
                    onChange={(e) => setValue('title', e.target.value)}
                />

                <PropertyItemTitle>Adaptation de l'image</PropertyItemTitle>
                <Select
                    fullWidth
                    value={getValue('objectFit')}
                    onChange={(e) => setValue('objectFit', e.target.value)}>
                    {
                        imageObjectFits.map((item, index) => (
                            <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
                        ))
                    }
                </Select>
            </PropertyContainer>
        </>
    )
}
