import { TAdvertEquipment } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useTranslation } from "react-i18next";
import { useAdvertEquipmentsService } from "./useAdvertEquipmentsService";

export const useAdvertEquipmentDelete = () => {
    const service = useAdvertEquipmentsService()
    const { i18n } = useTranslation()
    return useEphattaMutation(
        (payload: TAdvertEquipment) => service.delete(payload.id),
        {
            log: (data, variables) => {
                const value = variables?.values.find((item) => item.culture === i18n.language)
                return `Equipement <a href="/equipements">${value?.value}</a> supprimé.`
            }
        }
    )
}
