import { ButtonProps } from "@mui/material"
import { useMemo } from "react"
import { useCraftEditorParameters } from "./useCraftEditorParameters"

export const useButtonColors = () => {
    
    const [parameters] = useCraftEditorParameters()

    const options = useMemo(() => {
        return parameters?.properties?.button?.colorOptions
    }, [parameters])

    return useMemo((): Array<Props> => options || ([
        {
            label: 'Erreur',
            color: 'error'
        },
        {
            label: 'Info',
            color: 'info'
        },
        {
            label: 'Primaire',
            color: 'primary'
        },
        {
            label: 'Secondaire',
            color: 'secondary'
        },
        {
            label: 'Warning',
            color: 'warning'
        },
    ]), [options])
}


type Props = {
    label?: string
    color?: ButtonProps['color']
}

