import { useMemo } from "react"
import { useCraftEditorParameters } from "./useCraftEditorParameters"

export const useTextFontSizes = () => {

    const [parameters] = useCraftEditorParameters()

    const options = useMemo(() => {
        return parameters?.properties?.text?.fontSizeOptions
    }, [parameters])

    return useMemo(() => {
        return options || [
            {
                label: '9',
                value: '9'
            },
            {
                label: '10',
                value: '10'
            },
            {
                label: '11',
                value: '11'
            },
            {
                label: '12',
                value: '12'
            },
            {
                label: '14',
                value: '14'
            },
            {
                label: '16',
                value: '16'
            },
            {
                label: '18',
                value: '18'
            },
            {
                label: '20',
                value: '20'
            },
            {
                label: '22',
                value: '22'
            },
            {
                label: '25',
                value: '25'
            },
            {
                label: '28',
                value: '28'
            },
            {
                label: '30',
                value: '30'
            },
            {
                label: '36',
                value: '36'
            },
            {
                label: '48',
                value: '48'
            },
            {
                label: '72',
                value: '72'
            },
            {
                label: '96',
                value: '96'
            },
        ]
    }, [options])
}