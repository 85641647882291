
import { useEnv, useTranslations } from "@ephatta/components";
import { useMemo } from "react";

export const useDrawerMenu = (): DrawerMenu => {

    const env = useEnv()

    const [
        HOME,
        USER,
        ADVERT_MANAGEMENT,
        ADVERT_MANAGEMENT_ADVERT,
        ADVERT_MANAGEMENT_EQUIPEMENTS,
        ADVERT_MANAGEMENT_ENVIRONMENTS,
        BOOKING,
        TRANSACTION,
        CONTENT_MANAGEMENT,
        CONTENT_MANAGEMENT_STATICPAGE,
        CONTENT_MANAGEMENT_LANDINGPAGE,
        CONTENT_MANAGEMENT_ANNOUNCEPAGE,
        CONTENT_MANAGEMENT_DYNAMIC_CONTENT,
        COMMENT_MANAGEMENT,
        SUBSCRIBE_MANAGEMENT,
        SUBSCRIBE_MANAGEMENT_SUBSCRIBE,
        SUBSCRIBE_MANAGEMENT_SUBSCRIPTION,
        FACEBOOK_PUBLICATION,
        MOVEMENT_INTERWALLET,
        SEO,
        EXPORTS,
        EVENT_LOG,
        MO_USER,
        SECURITY_ALERT,
        EVENT,
        LOG_OUT
    ] = useTranslations(i18n)

    return useMemo((): DrawerMenu => ([
        {
            title: HOME,
            route: '/'
        },
        {
            title: USER,
            route: '/users'
        },
        {
            title: ADVERT_MANAGEMENT,
            route: '#',
            menus: [
                {
                    route: '/adverts',
                    title: ADVERT_MANAGEMENT_ADVERT
                },
                {
                    route: '/equipements',
                    title: ADVERT_MANAGEMENT_EQUIPEMENTS
                },
                {
                    route: '/environments',
                    title: ADVERT_MANAGEMENT_ENVIRONMENTS
                },
            ]
        },
        {
            title: BOOKING,
            route: '/booking'
        },
        {
            title: TRANSACTION,
            route: '/transaction'
        },
        {
            title: CONTENT_MANAGEMENT,
            route: '#',
            menus: [
                {
                    route: '/static-page',
                    title: CONTENT_MANAGEMENT_STATICPAGE
                },
                {
                    route: '/landing/content',
                    title: CONTENT_MANAGEMENT_LANDINGPAGE
                },
                {
                    route: '/landing/advert',
                    title: CONTENT_MANAGEMENT_ANNOUNCEPAGE
                },
                {
                    route: '/dynamic-content',
                    title: CONTENT_MANAGEMENT_DYNAMIC_CONTENT
                },
            ]

        },
        {
            title: COMMENT_MANAGEMENT,
            route: '/comments'
        },
        {
            title: SUBSCRIBE_MANAGEMENT,
            route: '#',
            menus: [
                {
                    route: '/subscribe',
                    title: SUBSCRIBE_MANAGEMENT_SUBSCRIBE
                },
                {
                    route: '/subscription',
                    title: SUBSCRIBE_MANAGEMENT_SUBSCRIPTION
                },
            ]
        },
        {
            title: FACEBOOK_PUBLICATION,
            route: '/facebook-publication'
        },
        {
            title: 'Admin',
            route: '#',
            menus: [
                {
                    route: '/movement-wallet',
                    title: MOVEMENT_INTERWALLET
                },
                {
                    route: '/seo',
                    title: SEO
                },
                {
                    route: '/export',
                    title: EXPORTS
                },
                {
                    route: '/event',
                    title: EVENT_LOG
                },
                {
                    route: '/mo-user',
                    title: MO_USER
                },
                // {
                //     route: '/security-alert',
                //     title: SECURITY_ALERT
                // },
            ]
        },
        {
            title: LOG_OUT,
            route: '/logout'
        },
    ]), [])
}

const i18n = [
    'MiddleOffice.Module.Home.Label',
    'MiddleOffice.Module.Users.Label',
    'MiddleOffice.Module.AdvertManagement.Label',
    'MiddleOffice.Module.AdvertManagement.Advert.Label',
    "MiddleOffice.Module.AdvertManagement.Equipements.Label",
    "MiddleOffice.Module.AdvertManagement.Environments.Label",
    "MiddleOffice.Module.Booking.Label",
    'MiddleOffice.Module.Transaction.Label',
    "MiddleOffice.Module.ContentManagement.Label",
    "MiddleOffice.Module.ContentManagement.StaticPage.Label",
    "MiddleOffice.Module.ContentManagement.LandingPage.Label",
    "MiddleOffice.Module.ContentManagement.AnnouncePage.Label",
    "MiddleOffice.Module.ContentManagement.DynamicContent.Label",
    "MiddleOffice.Module.CommentManagement.Label",
    "MiddleOffice.Module.SubscribeManagement.Label",
    "MiddleOffice.Module.SubscribeManagement.Subscribe.Label",
    "MiddleOffice.Module.SubscribeManagement.Subscription.Label",
    "MiddleOffice.Module.FacebookPublication.Label",
    "MiddleOffice.Module.Admin.Movement.InterWallet.Label",
    "MiddleOffice.Module.Admin.SEO.Label",
    "MiddleOffice.Module.Admin.Exports.Label",
    "MiddleOffice.Module.Admin.EventLog.Label",
    "MiddleOffice.Module.Admin.MO.User.Label",
    "MiddleOffice.Module.Admin.SecurityAlert.Label",
    "MiddleOffice.Module.Event.Label",
    "MiddleOffice.Module.Logout.Label",
]

export type DrawerMenu = Array<Menu | undefined>

export type Menu = {
    ke?: string
    title: string
    route: string
    icon?: any
    menus?: Array<Menu>,
    showSmScreen?: boolean,
}
