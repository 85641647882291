import { TCustomerDetails } from "@ephatta/services"
import { useEphattaMutation } from "@hooks/utils"
import { useCustomerService } from "./useCustomerService"

export const useCustomerDelete = () => {
  const service = useCustomerService()
  return useEphattaMutation(
    (payload: TCustomerDetails) => service.deleteUser(payload.customer.userId),
    {
      successMessage: "Utilisateur supprimé avec succès",
      log: (data, variables) => `Utilisateur supprimé - ${variables?.customer.firstname} ${variables?.customer.lastname}<${variables?.customer.email}>`
    }
  )
}
