import { EphattaRouteProps } from '@ephatta/components'
import { MainLayout } from '../components/layout/MainLayout'
import HomeRoutes from './home/Routes'
import AccountRoutes from './account/Routes'
import RouteAuthRoutes from './account/RouteAuth'
import CommentManagement from './comment-management/Routes'
import ContentManagementRoutes from './content-management/Routes'
import UsersRoutes from './users/Routes'
import AdvertManagementRoutes from './advert-management/Routes'
import SubscriptionManagementRoutes from './subscription-management/Routes'
import BookingRoutes from './booking/Routes'
import TransactionRoutes from './transaction/Routes'
import FacebookPublicationRoutes from './facebook-publication/Routes'
import EventRoutes from './event/Routes'
import AdminRoutes from './admin/Routes'

const Routes: EphattaRouteProps[] = [
    {
        path: '/',
        element: <MainLayout />,
        children: [
            ...HomeRoutes,
            ...AccountRoutes,
            ...EventRoutes,
            ...CommentManagement,
            ...ContentManagementRoutes,
            ...UsersRoutes,
            ...AdvertManagementRoutes,
            ...SubscriptionManagementRoutes,
            ...BookingRoutes,
            ...TransactionRoutes,
            ...FacebookPublicationRoutes,
            ...AdminRoutes,
        ]
    },
    ...RouteAuthRoutes
]

export default Routes
