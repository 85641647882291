import { Paper, PaperProps } from "@mui/material";
import React, { CSSProperties, ReactElement } from "react";
import { useNode } from "@craftjs/core";
import { ContainerProperty } from "../property/ContainerProperty";
import { BreakpointProperty } from "../property/BreakpointProperty";
import { useBreakpointMatch } from "components/craft-editor/hooks/useBreakpointMatch";
import { useGetSquareProperty } from "components/craft-editor/hooks/useGetSquareProperty";
import { useUnitWrapper } from "components/craft-editor/hooks/useUnitWrapper";

export const Container = ({ children, draggable, ...props }: ContainerProps) => {
    const { connectors: { connect, drag } } = useNode();

    const { container, padding, background, draggable: draggableDOM, ..._props } = useBreakpointMatch<ContainerProps>(props)
    const wrapUnit = useUnitWrapper()
    const getSquareProperty = useGetSquareProperty()

    return (
        <Paper
            {..._props}
            ref={(ref: HTMLDivElement)=> connect(drag(ref!))} 
            elevation={container?.elevation}
            className={`craft-container ${props.className}`}
            style={{
                display: 'flex',
                background,
                ...container,
                ...(container?.border as any || {}),
                margin: container?.margin ? getSquareProperty(container.margin) : '5px',
                padding: container?.padding ? getSquareProperty(container.padding) : `${padding}px`,
                width: wrapUnit(container?.width as string) || 'auto',
                height: wrapUnit(container?.height as string) || 'auto',
                borderWidth: wrapUnit(container?.border?.borderWidth as string) || 'auto',
                gap: wrapUnit(container?.gap) || 'unset',
                borderRadius: container?.border?.borderRadius ? getSquareProperty(container?.border?.borderRadius) : ``,
                ...props.style
            }}>
            {!container?.swiper && children}
        </Paper>
    )
}

export const ContainerDefaultProps = {
    flexDirection: 'column',
    // flex: "0 1 10%", 
    borderRadius: '4px',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 'auto',
    height: 'auto',
    elevation: 0,
    backgroundColor: 'transparent',
}

Container.craft = {
    displayName: 'Container',
    props: {
        container: ContainerDefaultProps,
    },
    rules: {
        canDrag: () => true,
    },
    related: {
        toolbar: ContainerProperty,
        breakpoint: BreakpointProperty
    },
};

export type ContainerProps = PaperProps & {
    background?: string,
    padding?: number,
    children?: ReactElement,
    container?: {
        flexDirection?: 'row' | 'column'
        flex?: number | string
        borderRadius?: CSSProperties['borderRadius']
        elevation?: PaperProps['elevation']
        justifyContent?: CSSProperties['justifyContent']
        alignItems?: CSSProperties['alignItems']
        width?: CSSProperties['width']
        height?: CSSProperties['height']
        minHeight?: string
        display?: CSSProperties['display']
        border?: {
            borderColor?: CSSProperties['borderColor']
            borderStyle?: CSSProperties['borderStyle']
            borderWidth?: CSSProperties['borderWidth']
        }
        [key: string]: any
    }
    draggabel?: boolean
    breackpoint?: string
}
