import { TLandingPage } from "@ephatta/services";
import { useEphattaMutation } from "hooks";
import { useLandigPageType } from "./useLandigPageType";
import { useLandingPageService } from "./useLandingPageService";

export const useLandingPageDelete = () => {
    const service = useLandingPageService()
    const getLandingPageType = useLandigPageType()
    return useEphattaMutation(
        (payload: TLandingPage) => service.delete(payload.id),
        {
            log: (data, variables) => {
                return `Landing page ${getLandingPageType(variables?.type || '')} <a href="/landing/content/edit/${variables?.id}">${variables?.title}</a> supprimé.`
            }
        }
    )
}
