import { useFormData } from "hooks"
import { TextFontSizeDialogValue } from "../TextFontSizeDialog"

export const useTextFontSizeFormData = (initialValue: TextFontSizeDialogValue) => {

    return useFormData<TextFontSizeDialogValue>({
        formData: initialValue,
        required: [
            'fontSize'
        ] ,
    })
}
